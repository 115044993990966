import styled from 'styled-components';
import { device } from '../mediaQuery';

export const RoomTypeLabel = styled.div`
  align-items: center;
  color: #a5a5a5;
  display: inline-flex;
  font-weight: normal;
  margin-right: ${(props) => (props.topbar ? '8px' : '12px')};12px;
  ${(props) =>
    !props.topbar &&
    `
    font-size: 1.25rem;
    height: 24px;
    padding-top: 4px;
    @media ${device.mobileL} {
      margin-right: 4px;
      padding-top: 0;
    }
  `}
`;

export const RoomType = styled.div`
  @media ${device.mobileL} {
    display: none;
  }
`;

export const RoomTypeIcon = styled.img`
  display: inline-block;
  height: ${(props) => (props.topbar ? '16px' : '24px')};
  width: ${(props) => (props.topbar ? '16px' : '24px')};
  margin-right: ${(props) => (props.topbar ? '4px' : '6px')};
`;
