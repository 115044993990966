import { v4 as uuidv4 } from 'uuid';
import { GlobalLaunchState } from './launchState';
import { fetchWithTimeout } from './fetchImproved';
import { logger } from './logger';

const TERMINAL_CONNECT_API_KEY = import.meta.env.VITE_TERMINAL_CONNECT_API_KEY;

if (GlobalLaunchState.isProd || GlobalLaunchState.isDev) {
  if (!TERMINAL_CONNECT_API_KEY) {
    throw new Error(
      'Terminal Connect GraphQL Server API Key is not provided in the build.'
    );
  }
}

const TERMINAL_CONNECT_GQL_SERVER_URL =
  'http://localhost:9000/terminal_connect/v3';

const BSSO_TOKEN_QUERY = `{
  bssoToken {
    ... on BssoToken {
      accessToken
      __typename
    }
    ... on Error {
      errorCategory
      errorMessage
      __typename
    }
    __typename
  }
}`;

export async function getBssoAccessTokenFromTerminalConnect(): Promise<
  string | null
> {
  if (GlobalLaunchState.isLocal) {
    // For local development, set the value of VITE_TERMINAL_CONNECT_DEV_TOKEN by
    // fetching it with a GraphQL client like Postman or Insomnia on the same host/VM
    // where you are running the Terminal.
    return import.meta.env.VITE_TERMINAL_CONNECT_DEV_TOKEN || null;
  }

  const errorPrefix =
    'Error fetching BSSO Token from Terminal Connect GraphQL server:';

  try {
    // Make GraphQL query using fetch API
    // Reference: https://graphql.org/graphql-js/graphql-clients/

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: `Bearer ${TERMINAL_CONNECT_API_KEY}`,
    };

    const body = JSON.stringify({ query: BSSO_TOKEN_QUERY, variables: {} });

    // DRQS 171130034 Timeout Terminal Connect GraphQL requests to unblock login
    const response = await fetchWithTimeout(TERMINAL_CONNECT_GQL_SERVER_URL, {
      method: 'POST',
      headers,
      body,
    });

    if (!response.ok) {
      logger.error(`${errorPrefix} ${response.status} ${response.statusText}`);
      return null;
    }

    const queryResult = await response.json();
    if (queryResult.data?.bssoToken?.accessToken) {
      return queryResult.data.bssoToken.accessToken;
    }

    logger.error(
      `${errorPrefix} errorCategory = ${queryResult.data?.bssoToken?.errorCategory}, errorMessage = ${queryResult.data?.bssoToken?.errorMessage}`
    );
  } catch (e) {
    logger.error(`${errorPrefix} ${JSON.stringify(e)}`);
  }
  return null;
}

export async function loginWithBssoAccessToken(
  bssoAccessToken: string
): Promise<boolean> {
  const traceId = uuidv4();
  const url = '/api/login/oauth2/tc';
  const method = 'POST';

  logger.info(`NETWORK [REQUEST ${traceId}]: ${method} ${url}`);

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Trace-Id': traceId,
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify({ accessToken: bssoAccessToken }),
    });

    if (!response.ok) {
      logger.error(
        `NETWORK [RESPONSE ${traceId}]: ${method} ${url} Error - ${response.status} ${response.statusText}`
      );
      return false;
    }

    logger.info(
      `NETWORK [RESPONSE ${traceId}]: ${method} ${url} ${response.status}`
    );
    return true;
  } catch (error) {
    logger.error(
      `NETWORK [FAILURE ${traceId}]: ${method} ${url} Error - ${JSON.stringify(
        error
      )}`
    );
  }
  return false;
}
