import React from 'react';
import CustomContextualMessage from './ContextMessage';
import styled from 'styled-components';

export default function BBUIContextMessageList({
  messages,
}: {
  messages: {
    id: number;
    message: string;
    title: string | null;
    icon: string | null;
  }[];
}) {
  return (
    <MessageList>
      {messages.map(
        (message: {
          id: number;
          message: string;
          title: string | null;
          icon: string | null;
        }) => {
          return (
            <CustomContextualMessage
              id={message.id}
              message={message.message}
              title={message.title}
              icon={message.icon}
            />
          );
        }
      )}
    </MessageList>
  );
}

const MessageList = styled.ul`
  z-index: 5;
  height: inherit;
  width: min-content;
  list-style: none;
`;
