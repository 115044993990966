import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export type UserType = {
  firmId: string;
  loginJwt: string;
  uuid: string;
  username: string;
  email: string;
  fullName: string;
  userType: 'bloomberg-employee' | 'bloomberg-client' | 'bloomberg-qa-tester';
  sessionId: string;
  wibCisToken: string;
  phoneNumber: string;
  ibEnabled: boolean;
  dialInEnabled: boolean;
  localRecordingEnabled: boolean;
  serverSideRecordingEnabled: boolean;
  vhubEnabled: boolean;
  isInternalUser: boolean;
  isQaTester: boolean;
  isClient: boolean;
  restricted: boolean;
  userInitials: string;
};

export type SessionCookie = {
  loginJwt: string;
  wibCisToken: string;
};

export type LoginJwt = {
  username: string;
  sessionId: string;
  emailAddress: string;
  uuid: number;
  firmId: number;
  phoneNumber: string;
  userType: 'bloomberg-employee' | 'bloomberg-client' | 'bloomberg-qa-tester';
  principal: string;
  aud: string;
  sub: 'jitsi';
  iss: string;
  isDialInEnabled: boolean;
  isLocalRecordingEnabled: boolean;
  isServerSideRecordingEnabled: boolean;
  isVHUBEnabled: boolean;
  isWebIbEnabled: boolean;
  type: 'user-login' | 'restricted-user-login';
  fullName: string;
  userInitials: string;
};

export type Invitation = {
  roomId: string;
  invitedByName: string;
  createdAt: string;
};

export type ErrorResponse = {
  error: any;
};

// TODO: replace { [key: string]: any } with a union of all known response types
export type DataResponse = Invitation[] | { [key: string]: any };

export type Response =
  | DataResponse
  | ErrorResponse
  | GetPersonalRoomResponse
  | GetParticipantCountResponse;

export type RequestParams = {
  url: string;
  method?: RequestMethod;
  bearerToken: string;
  body?: string;
  roomId?: string;
};

export enum RequestMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export type HttpResponse = {
  status: number;
  [key: string]: any;
};

export type PhoneAPIResponseType = {
  roomId: string;
  dialInInfo: PhoneCoordinate[];
};

export type GetPersonalRoomResponse = {
  displayName: string;
  roomDetailUrl: string;
  roomId: string;
  roomJoinInstructions: string[];
  roomJoinUrl: string;
  roomType: string;
};

export type GetParticipantCountResponse = {
  participants: number;
};

type PhoneCoordinate = {
  phoneNumber: string;
  phoneExtension: number;
  phonePin: number;
  countryCode: string;
};

export type PhoneNumberDetail = {
  id: string;
  number: string;
  ext: number;
  pin: number;
};

export type FormattedPhoneNumberProps = {
  type: string;
  number: string;
  ext: number;
};

export type ClickToDialProps = {
  firstName: string;
  lastName: string;
  phone: string;
};

export enum CallStatus {
  Disconnected,
  CallPlaced,
}

// defining types of full screen events per browser
export type DocumentElement = {
  requestFullscreen?: Function;
  mozRequestFullScreen?: Function;
  webkitRequestFullscreen?: Function;
};

// adding additional cancel full screen handlers per browser
export interface userDocument extends Document {
  mozCancelFullScreen?: Function;
  webkitExitFullscreen?: Function;
}

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export class BbgHoliday {
  name: string;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;

  constructor(name: string, start: string, end: string) {
    this.name = name;
    this.start = dayjs(start);
    this.end = dayjs(end);
  }

  hasStarted() {
    const now = dayjs();
    return now.isSameOrAfter(this.start) && now.isSameOrBefore(this.end);
  }
}

export const BbgHolidays = [
  new BbgHoliday(
    'winter',
    `2023-12-11T00:00:00.000Z`,
    `2024-01-01T00:00:00.000Z`
  ),
];

export interface RoomInfo {
  roomId: string;
  displayName: string;
  participantCount?: number;
}
