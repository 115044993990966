import styled from 'styled-components';
import { Tooltip } from '@bbnpm/bb-ui-framework';
import { makeNamesList } from '../common/util';

export default function HandRaiseIndicator({ isNarrow, handRaisers }) {
  if (!Object.keys(handRaisers).length) {
    return null;
  }

  return (
    <>
      {isNarrow ? (
        <HandRaise>
          <HandRaiseIcon src='/svg/ic_lower-hand.svg' />
          <HandRaiseBadgeCount>
            {Object.keys(handRaisers).length}
          </HandRaiseBadgeCount>
        </HandRaise>
      ) : (
        <Tooltip
          content={
            <p>
              <span>
                {makeNamesList(Object.values(handRaisers)) + ' '}
                raised their hand
              </span>
            </p>
          }
        >
          <HandRaise>
            <HandRaiseIcon src='/svg/ic_lower-hand.svg' />
            <HandRaiseBadgeCount>
              {Object.keys(handRaisers).length}
            </HandRaiseBadgeCount>
          </HandRaise>
        </Tooltip>
      )}
    </>
  );
}

const HandRaise = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.25rem 0 0.5rem;
`;

const HandRaiseIcon = styled.img`
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
`;

const HandRaiseBadgeCount = styled.div`
  background-color: #fc9170;
  border-radius: 100%;
  color: #fff;
  cursor: default;
  font-size: 0.6875rem;
  line-height: 1rem;
  min-width: 1rem;
  text-align: center;
`;
