import React, {
  lazy,
  useEffect,
  useState,
  useRef,
  useContext,
  Suspense,
  useCallback,
} from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useRouteLoaderData,
  Link,
} from 'react-router-dom';
import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css';
import styled from 'styled-components';
import {
  useNotification,
  Content,
  Banner,
  Button,
  Breadcrumbs,
  T,
} from '@bbnpm/bb-ui-framework';
import { inviteUserToRoom } from '../common/invitation';
import { logger } from '../common/logger';
import { device } from '../common/mediaQuery';
import { copyToClipboard, sanitizeUrl } from '../common/util';
import TopBar from '../topBar';
import {
  broadcastLeaveOtherRoomsMessage,
  useMuteOtherRooms,
  broadcastRoomJoinedMessage,
} from './broadcastChannel';
import Jitsi from './Jitsi';
import ParticipantListInPreview from './ParticipantListInPreview';
import {
  isIbBasedRoom,
  isValidIbChatId,
  usePromptBeforeTabCloseOrRefresh,
} from './util';
import DialInModal from './DialInModal';
import RecordingMsgModal from './RecordingMsgModal';
import { getRandomHolidayIcon, getBbgHolidayName } from '../common/util';
import { GlobalLaunchState } from '../common/launchState';
import { RoomTypeLabel } from '../common/components/RoomLabelAssets';
import RoomTypeIndicator from '../common/components/RoomTypeIndicator';
import { UserContext } from '../common/user';

const IBWithErrorBoundary = lazy(() => import('./IB'));

export default function Room() {
  const user = useContext(UserContext);
  const { roomId } = useParams();

  const [jitsiApi, setJitsiApi] = useState(undefined);
  const [roomJoined, setRoomJoined] = useState(false);
  const [speaker, setSpeaker] = useState(undefined);
  const [recorder, setRecorder] = useState(undefined);
  const [recordingStatus, setRecordingStatus] = useState(undefined);
  const [handRaisers, setHandRaisers] = useState({});
  const [remoteScreenShares, setRemoteScreenShares] = useState([]);
  const [pinnedParticipantId, setPinnedParticipantId] = useState(null);
  const [selfScreenShare, setSelfScreenShare] = useState({
    sharing: false,
    shareID: null,
  });
  const [messages, setmessages] = useState(Array);
  const [chatId, setChatId] = useState(undefined);
  const [ibOpen, setIbOpen] = useState(false);
  const [pollsPaneOpen, setPollsPaneOpen] = useState(false);
  const [participantsPaneOpen, setParticipantsPaneOpen] = useState(false);
  const [dialInOpen, openDialInModal] = useState(false);
  const [recMsgOpen, setOpenRecordingMsgModal] = useState(false);
  const [bannerOtherRoomsMuted, setBannerOtherRoomsMuted] = useState(false);

  const { shard, roomAuth, region } = useRouteLoaderData('rooms-router');
  const holiday = getBbgHolidayName();
  const holidayIcon = useRef(getRandomHolidayIcon(holiday));

  const timeOut = 5000;
  // this code is to list and auto dismiss contextual messages
  const cleanUpList = useCallback((newItem) => {
    setTimeout(() => {
      setmessages((prevList) => prevList.filter((item) => item !== newItem));
    }, timeOut);
  }, []);

  usePromptBeforeTabCloseOrRefresh(GlobalLaunchState.isWeb && roomJoined);
  useMuteOtherRooms({
    roomJoined,
    jitsiApi,
    setBannerOtherRoomsMuted,
  });
  useLogJoinPreview(roomAuth);
  useBroadcastJoinRoom(roomJoined);
  useMarkPreviewInUrlHash(roomJoined);
  useSetLoggerRoomId(roomId, logger);

  const notification = useNotification();

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      {bannerOtherRoomsMuted && (
        <Banner
          kind='warning'
          message='You have joined multiple ROOM meetings. Your microphone and camera have been turned off in other meetings.'
          link={
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                broadcastLeaveOtherRoomsMessage();
                setBannerOtherRoomsMuted(false);
              }}
            >
              Leave other meetings.
            </a>
          }
          onClose={() => setBannerOtherRoomsMuted(false)}
        />
      )}
      <TopBar
        inRoom={!!roomJoined}
        ibOpen={ibOpen}
        ibReady={isValidIbChatId(chatId)}
        setIbOpen={setIbOpen}
        roomId={roomId}
        user={user}
        speaker={speaker}
        recorder={recorder}
        recordingStatus={recordingStatus}
        handRaisers={handRaisers}
        remoteScreenShares={remoteScreenShares}
        pinnedParticipantId={pinnedParticipantId}
        selfScreenShare={selfScreenShare}
        ibBasedRoom={isIbBasedRoom(roomAuth?.room.roomType)}
        roomName={roomAuth?.room.displayName}
        roomType={roomAuth?.room.roomType}
        jitsiApi={jitsiApi}
        messages={messages}
        participantsPaneOpen={participantsPaneOpen}
        pollsPaneOpen={pollsPaneOpen}
      />
      {!roomJoined && (
        <StyledHorizontalPane>
          {GlobalLaunchState.isWeb ? (
            <Breadcrumbs>
              <Breadcrumbs.Link>
                <Link to='/'>Home</Link>
              </Breadcrumbs.Link>
              <Breadcrumbs.Link>Meeting Preview</Breadcrumbs.Link>
            </Breadcrumbs>
          ) : (
            <T.H6>Meeting Preview</T.H6>
          )}
          <RoomName>
            <RoomTypeLabel TopBar={false}>
              <RoomTypeIndicator roomType={roomAuth?.room?.roomType} />
            </RoomTypeLabel>

            {roomAuth?.room.displayName}

            {holiday && (
              <img
                src={holidayIcon.current}
                alt=''
                width='22px'
                style={{ marginLeft: '10px' }}
              />
            )}
          </RoomName>
        </StyledHorizontalPane>
      )}
      <StyledContent $inRoom={!!roomJoined}>
        <LeftPanel inRoom={!!roomJoined}>
          <Jitsi
            user={user}
            roomAuth={roomAuth}
            jitsiApi={jitsiApi}
            setJitsiApi={setJitsiApi}
            setRoomJoined={setRoomJoined}
            roomJoined={roomJoined}
            setSpeaker={setSpeaker}
            setIbOpen={setIbOpen}
            setParticipantsPaneOpen={setParticipantsPaneOpen}
            setPollsPaneOpen={setPollsPaneOpen}
            openDialInModal={openDialInModal}
            onContextMessage={(newMessages) => {
              setmessages((prevMessages) => {
                cleanUpList(newMessages);
                return [...prevMessages, newMessages];
              });
              cleanUpList();
            }}
            shard={shard}
            region={region}
            onRecorderChanged={({ id, name, isRecording }) => {
              if (isRecording) {
                setRecorder({ id, name });
              } else {
                // Recorder has probably left or recording has stopped
                setRecorder(undefined);
              }
            }}
            onRecordingStatusChanged={({ on, error }) => {
              if (error) {
                setRecordingStatus(false);
              } else {
                setOpenRecordingMsgModal(on);
                setRecordingStatus(on);
              }
            }}
            onHandRaised={({ id, name, handRaised }) => {
              if (handRaised) {
                setHandRaisers({ ...handRaisers, [id]: name });
              } else {
                // Participant has probably left or has lowered their hand
                const updatedHandRaisers = { ...handRaisers };
                delete updatedHandRaisers[id];
                setHandRaisers(updatedHandRaisers);
              }
            }}
            onScreenShareStatusChanged={(remoteScreenShares) => {
              setRemoteScreenShares(remoteScreenShares);
            }}
            onPinnedScreenShareChanged={(pinnedParticipantId) => {
              setPinnedParticipantId(pinnedParticipantId);
            }}
            onSelfScreenShareChanged={(selfScreenShare) => {
              setSelfScreenShare(selfScreenShare);
            }}
          ></Jitsi>
          {!roomJoined && (
            <Footnote>
              <div>
                {user?.dialInEnabled && (
                  <DialInButton
                    kind='secondary'
                    onClick={() => openDialInModal(true)}
                  >
                    Join via Phone
                  </DialInButton>
                )}
                <JoinFromAnotherDeviceButton
                  kind='secondary'
                  onClick={() => {
                    inviteUserToRoom(user.uuid, roomId, user.loginJwt);
                    notification.addInfo({
                      message:
                        'You can now join this meeting on another device at blproom.com.',
                    });
                  }}
                  aria-label='Join ROOM from another device'
                >
                  Join from Another Device
                </JoinFromAnotherDeviceButton>
                <CopyUrlButton
                  kind='secondary'
                  onClick={() => {
                    copyToClipboard(sanitizeUrl(window.location.href));
                    notification.addInfo({
                      message: 'Copied meeting web link.',
                    });
                  }}
                  aria-label='Copy a web link to this ROOM meeting'
                >
                  Copy Web Link
                </CopyUrlButton>
              </div>
              <OtherDeviceMessage>
                Visit blproom.com to access ROOM for all your IB chats
                {user.isInternalUser ? ' and APPTs.' : '.'}
              </OtherDeviceMessage>
            </Footnote>
          )}
          {user?.dialInEnabled && (
            <DialInModal
              user={user}
              roomId={roomId}
              isOpen={dialInOpen}
              openDialInModal={openDialInModal}
            />
          )}
          <RecordingMsgModal
            isOpen={recMsgOpen}
            setIsOpen={setOpenRecordingMsgModal}
            jitsiApi={jitsiApi}
          />
        </LeftPanel>
        {!roomJoined && <MiddleSeparator />}
        {!roomJoined && (
          <RightPanel ratio='3/4'>
            <ParticipantListInPreview user={user} roomAuth={roomAuth} />
          </RightPanel>
        )}
        {roomAuth &&
          isIbBasedRoom(roomAuth.room?.roomType) &&
          isValidIbChatId(roomAuth.room?.ibChatId) && (
            <Suspense>
              <IBWithErrorBoundary
                user={user}
                roomAuth={roomAuth}
                ibOpen={ibOpen}
                jitsiApi={jitsiApi}
                setChatId={setChatId}
              />
            </Suspense>
          )}
      </StyledContent>
    </>
  );
  /* eslint-enable jsx-a11y/anchor-is-valid */
}

function useLogJoinPreview(roomAuth) {
  useEffect(() => {
    if (roomAuth) {
      logger.info(`Joined Preview Screen`);
    }
  }, [roomAuth]);
}

function useBroadcastJoinRoom(roomJoined) {
  useEffect(() => {
    if (roomJoined) {
      broadcastRoomJoinedMessage();
    }
  }, [roomJoined]);
}

/* eslint-disable react-hooks/exhaustive-deps */
function useMarkPreviewInUrlHash(roomJoined) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (roomJoined) {
      navigate({ ...location, hash: '' }, { replace: true });
    } else {
      navigate({ ...location, hash: '#preview' }, { replace: true });
    }
  }, [roomJoined, navigate]); // FIXME: Adding `location` to dependencies array prevents meeting hangup
}
/* eslint-enable react-hooks/exhaustive-deps */

function useSetLoggerRoomId(roomId, logger) {
  useEffect(() => {
    if (roomId && logger) {
      logger.setCustomHeaders({ 'X-Room-Id': roomId });
    }
    return () => logger.removeCustomHeaders('X-Room-Id');
  }, [roomId, logger]);
}

const DialInButton = styled(Button)`
  margin: 8px 8px;
`;

const StyledHorizontalPane = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  @media ${device.tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
  > nav {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  > h1 {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
  }
  > h4 {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

const StyledContent = styled(Content)`
  padding-left: ${({ $inRoom }) => ($inRoom ? '0%' : '3%')};
  padding-right: ${({ $inRoom }) => ($inRoom ? '0%' : '3%')};
  display: flex;
  flex-direction: row;
  overflow-y: ${({ $inRoom }) => ($inRoom ? 'hidden' : 'scroll')};
  overflow-x: hidden;
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.tabletS} {
    padding-left: 0%;
    padding-right: 0%;
  }
`;

const LeftPanel = styled.div`
  margin-top: ${(props) => (props.inRoom ? 'auto' : '20px')};
  margin-bottom: ${(props) => (props.inRoom ? 'auto' : '40px')};
  display: flex;
  flex-direction: column;
  flex: 3;
  height: ${(props) => (props.inRoom ? '100%' : 'unset')};
  overflow: ${(props) => (props.inRoom ? 'hidden' : 'unset')};
  @media ${device.tablet} {
    height: 100%;
  }
`;

const MiddleSeparator = styled.div`
  width: 7vw;
  flex: 0.3;
  @media ${device.tablet} {
    flex: 0;
  }
  @media ${device.tabletS} {
    flex: 0;
  }
`;

const RightPanel = styled(AspectRatio)`
  margin-top: ${(props) => (props.inRoom ? 'auto' : '20px')};
  margin-bottom: ${(props) => (props.inRoom ? 'auto' : '40px')};
  overflow: hidden;
  flex: 2;
  @media ${device.tablet} {
    overflow: visible;
  }
`;

// Adding a copy button for the room's url
const CopyUrlButton = styled(Button)`
  margin: 8px 8px;
`;

// Adding join from another device button
const JoinFromAnotherDeviceButton = styled(Button)`
  margin: 8px 8px;
`;

const Footnote = styled.div`
  margin-top: 32px;
  z-index: 2;
  text-align: center;
`;

const OtherDeviceMessage = styled.p`
  padding: 0 8px;
  margin-top: 12px;
`;

const RoomName = styled(T.H2)`
  display: flex;
  font-weight: 500;
  align-items: center;
`;
