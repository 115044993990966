// The following import is necessary first to load launch state,
// make it invariant, and sanitize the url in the address bar.
// This can then execute before the React app is initialized.
import { GlobalLaunchState } from './common/launchState';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Log the launched state for diagnosing issues
console.log(`App Instance ID: ${GlobalLaunchState.appInstanceId}`);
console.log(`App Mode: ${GlobalLaunchState.appMode}`);
console.log(`App Environment: ${GlobalLaunchState.appEnv}`);
console.log(`BEVO Session ID: ${GlobalLaunchState.bevoSessionId || 'UNKNOWN'}`);
console.log(
  `BSSO OpenToken: ${GlobalLaunchState.shortLivedBssoOpenToken || 'UNKNOWN'}`
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
