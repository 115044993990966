import React, { useContext, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Content,
  Footer,
  useNotification,
  TitleBar,
  Tabs,
} from '@bbnpm/bb-ui-framework';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useMediaQuery } from 'react-responsive';
import { device } from '../common/mediaQuery';
import { getRoom, gotoRoom } from '../common/api';
import {
  getSelfInvitations,
  markInvitationAlreadyHandledByUser,
} from '../common/invitation';
import Appt from './Appt';
import IbChatList from './IbChatList';
import TopBar from '../topBar';
import DialInModal from '../room/DialInModal';
import InternalTopSection from './InternalTopSection';
import { RoomInfo } from '../types';
import { UserContext } from '../common/user';
import { useRouteLoaderData } from 'react-router-dom';

export const getInvitationsSinceSecondsAgo = 60 * 20;

function Home() {
  const user = useContext(UserContext);
  let { selfInvitationsResponse, invitedRoomsResponse }: any =
    useRouteLoaderData('home-router');
  const isNarrowScreen = useMediaQuery({ query: device.tablet });
  const notification = useNotification();

  const [dialInOpen, openDialInModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<RoomInfo>({
    roomId: '',
    displayName: '',
    participantCount: 0,
  });
  const initialLoadDone = useRef(false);

  useEffect(checkSelfInvitations, [
    user,
    notification,
    selfInvitationsResponse,
    invitedRoomsResponse,
  ]);

  if (user?.restricted)
    return (
      <>
        <TitleBar productName='ROOM'></TitleBar>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: '1',
              fontSize: '23px',
              lineHeight: '30px',
              padding: '32px',
            }}
          >
            <p>
              Bloomberg ROOM allows you to hold voice and video meetings and
              share your screen with other Bloomberg users.
            </p>
            <p>
              To inquire about full access, contact John Waanders
              (jwaanders@bloomberg.net).
            </p>
          </div>
        </Content>
        <Footer copyright={new Date().getFullYear()} />
      </>
    );

  return (
    <>
      <TopBar />
      <Content>
        {user?.isInternalUser ? (
          <InternalTopSection
            isNarrowScreen={isNarrowScreen}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
            openDialInModal={openDialInModal}
          />
        ) : (
          <ClientTopSection isNarrowScreen={isNarrowScreen} />
        )}

        <WidgetSection>
          {user?.isInternalUser ? <InternalWidget /> : <ClientWidget />}
        </WidgetSection>
      </Content>
      {user?.dialInEnabled && (
        <DialInModal
          roomId={selectedRoom.roomId}
          isOpen={dialInOpen}
          openDialInModal={openDialInModal}
        />
      )}
      <Footer copyright={new Date().getFullYear()} />
    </>
  );

  function isInvitationWithinAutoJoinThreshold(createdTime: string) {
    const invitationThresholdInMS = 5 * 60 * 1000;
    return (
      new Date().getTime() - new Date(createdTime).getTime() <=
      invitationThresholdInMS
    );
  }

  function checkSelfInvitations() {
    if (!user || !notification) return () => {};

    if (!initialLoadDone.current) {
      notifyOnInvitations();
      initialLoadDone.current = true;
    }

    window.addEventListener('focus', notifyOnInvitations);
    return () => window.removeEventListener('focus', notifyOnInvitations);

    async function notifyOnInvitations() {
      const selfInvitations = !initialLoadDone.current
        ? selfInvitationsResponse
        : await getSelfInvitations(user, getInvitationsSinceSecondsAgo);

      if (
        selfInvitations.length === 1 &&
        isInvitationWithinAutoJoinThreshold(selfInvitations[0].createdAt)
      ) {
        markInvitationAlreadyHandledByUser(selfInvitations[0]);
        gotoRoom(selfInvitations[0].roomId);
      } else if (selfInvitations.length > 1) {
        const invitedRooms = !initialLoadDone.current
          ? invitedRoomsResponse
          : await Promise.all(
              selfInvitations.map((selfInvitation) =>
                getRoom(selfInvitation.roomId, user.loginJwt)
              )
            );

        invitedRooms.forEach((invitedRoom, idx) => {
          notification.addInfo({
            message: (
              <AClickable
                onClick={() => {
                  markInvitationAlreadyHandledByUser(selfInvitations[idx]);
                  gotoRoom((invitedRoom as RoomInfo).roomId);
                }}
              >
                Join meeting: {(invitedRoom as RoomInfo).displayName}
              </AClickable>
            ),
            onClose: () => {
              markInvitationAlreadyHandledByUser(selfInvitations[idx]);
            },
          });
        });
      }
    }
  }
}

const ClientTopSection = ({ isNarrowScreen }: { isNarrowScreen: boolean }) => (
  <ClientTopSectionContainer isNarrowScreen={isNarrowScreen}>
    <AppDescription>
      Bloomberg ROOM allows you to hold voice and video meetings and share your
      screen with other Bloomberg users. Choose a room to start or join a
      meeting.
    </AppDescription>
  </ClientTopSectionContainer>
);

const ClientWidget = () => (
  <ComponentContainer>
    <StyledGrid>
      <StyledRow center='sm'>
        <StyledCol sm={8} xs={12}>
          <IbChatList />
        </StyledCol>
      </StyledRow>
    </StyledGrid>
  </ComponentContainer>
);

const InternalWidget = () => {
  const isNarrowScreen = useMediaQuery({ query: device.tablet });
  const [activeTab, setActiveTab] = useState(0);
  return (
    <ComponentContainer>
      <StyledTabs onTabChange={setActiveTab}>
        <StyledTabsPane title='Appointments'></StyledTabsPane>
        <StyledTabsPane title='IB Chats'></StyledTabsPane>
      </StyledTabs>
      <StyledGrid isInternalUser={true}>
        <StyledRow>
          <StyledCol
            lg={6}
            md={6}
            sm={6}
            xs={12}
            hidden={isNarrowScreen && activeTab !== 0}
          >
            <Appt />
          </StyledCol>
          <StyledCol
            lg={6}
            md={6}
            sm={6}
            xs={12}
            hidden={isNarrowScreen && activeTab !== 1}
          >
            <IbChatList />
          </StyledCol>
        </StyledRow>
      </StyledGrid>
    </ComponentContainer>
  );
};

const ClientTopSectionContainer = styled.div<{
  isNarrowScreen: boolean;
}>`
  display: flex;
  justify-content: center;
  height: 120px;
  align-items: center;
  padding: ${(props) => (props.isNarrowScreen ? '35px 20px' : '16px 40px')};
`;

const AppDescription = styled.div`
  max-width: 672px;
  line-height: 24px;
`;

const WidgetSection = styled.div`
  background-image: url(/bgHero.svg);
  background-color: ${({ theme }) => theme.colors.backgroundShades.strong};
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const StyledGrid = styled(Grid)<{ isInternalUser?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 0;
  @media ${device.tablet} {
    height: ${(props) =>
      props.isInternalUser ? `calc(100% - ${TABS_OFFSET}px)` : '100%'};
    padding: 0 1rem;
    display: block;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledTabs = styled(Tabs)`
  display: none;
  @media ${device.tablet} {
    padding: 0 1rem;
    display: block;
  }
`;

const StyledTabsPane = styled(Tabs.Pane)`
  background-color: ${({ theme }) => theme.colors.backgroundShades.soft};
  &[aria-selected='false'] {
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.backgroundShades.medium} !important;
    }
    &:active {
      background-color: ${({ theme }) =>
        theme.colors.backgroundShades.strong} !important;
    }
  }
`;

const StyledCol = styled(Col)<{ hidden?: boolean }>`
  padding: 0.75vw;
  height: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  @media ${device.tablet} {
    padding: 0 0.5rem;
  }
`;

const ComponentContainer = styled.div`
  margin: 40px 0px;
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  padding: 0 5.25vw;
  @media ${device.tablet} {
    margin: 12px 0px;
    padding: 0;
  }
`;

const AClickable = styled.a`
  cursor: pointer;
`;

const TABS_OFFSET = 42;

export default Home;
