import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { BbgHoliday, BbgHolidays, HttpResponse } from '../types';

// Use same logic as IB: splits the string by spaces and returns all first characters; Words starting with a symbol are skipped.
// Reference: https://bbgithub.dev.bloomberg.com/ib-apps/ib2/blob/master/ib-session/client/pipelines/room-info-pipeline.ts#L37
// Add '+' on the initials for any transient chat that has more than 2 people
export function getInitials(chatTitle: string): string {
  // Checks for | (pipe) is temporary, it will be deleted soon.
  if (!chatTitle) {
    return '';
  }
  let firstPersonName = '';
  if (chatTitle.includes('|')) {
    firstPersonName = chatTitle.split(' | ')[0];
  } else {
    firstPersonName = chatTitle.split(', ')[0];
  }

  let words = firstPersonName.split(' ');
  let initials =
    words[0].replace(/[^A-Z]/, '')[0] +
    words[words.length - 1].replace(/[^A-Z]/, '')[0];

  if (initials.length > 2) {
    initials = initials.charAt(0) + initials.charAt(initials.length - 1);
  }

  if (chatTitle.includes(',') || chatTitle.includes('|')) {
    initials += '+';
  }

  return initials;
}

export function clearCookies() {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName, { secure: true });
    Cookies.remove(cookieName, { sameSite: 'Lax' });
    Cookies.remove(cookieName);
  });
}

export function isFirstTimeUser() {
  return (
    !window.localStorage.getItem('ROOM_PREVIOUS_LOGIN') &&
    !window.localStorage.getItem('ROOM_VISITED_LANDING')
  );
}

// Reference: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export function isStorageAvailable(storageType) {
  let storage;
  try {
    storage = window[storageType];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function sanitizeUrl(url: string): string {
  // Strip out all query params and hash fragments from the given URL
  return url.replace(/(\?.*)|(#.*)/g, '');
}

export function copyToClipboard(newClip: string): void {
  navigator.clipboard.writeText(newClip);
}

export function hasUrlParam(param: string): boolean {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has(param);
}

export function getUrlParam(param: string): string | null {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParam = urlParams.get(param);
  // URL launched from the Terminal could accidentally have invalid strings like 'undefined' or 'null'
  if (urlParam && (urlParam === 'undefined' || urlParam === 'null')) {
    return null;
  }
  return urlParam;
}

export function safeDecodeJwt(token: string): any {
  try {
    return jwt_decode(token);
  } catch (err) {
    return null;
  }
}

export async function safeParseResponseAsJson(
  response: HttpResponse
): Promise<any> {
  try {
    return await response.json();
  } catch (e) {
    // Empty response body (expected for some APIs) or unable to parse it as JSON
    return {};
  }
}

// holiday dates
export function getBbgHolidayName(): string | undefined {
  const activeHolidays: BbgHoliday[] = BbgHolidays.filter((holiday) =>
    holiday.hasStarted()
  );
  return activeHolidays[0]?.name;
}

// random holiday icons
export function getRandomHolidayIcon(holiday: string | undefined): string {
  if (!holiday) return '';

  const dir = `../holidays/${holiday}/`;
  const file =
    holidayIcons[holiday][
      Math.floor(Math.random() * holidayIcons[holiday].length)
    ];

  return `${dir}${file}`;
}

const holidayIcons: { [key: string]: string[] } = {
  winter: [
    'candle.svg',
    'cloud-with-snow.svg',
    'confetti-ball.svg',
    'evergreen-tree.svg',
    'glowing-star.svg',
    'hot-beverage.svg',
    'ice-skate.svg',
    'maple-leaf.svg',
    'party-popper.svg',
    'scarf.svg',
    'skier.svg',
    'skis.svg',
    'sled.svg',
    'snowboarder.svg',
    'snowflake.svg',
    'snowman-without-snow.svg',
    'snowman.svg',
    'teacup-without-handle.svg',
  ],
};

export function makeNamesList(arr: String[]) {
  if (!arr || !arr.length) return '';

  if (arr.length === 1) return arr[0];

  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];

  if (arr.length === 2) return firsts + ' and ' + last;

  return firsts.join(', ') + ', and ' + last;
}
