import { useEffect } from 'react';

export function isIbBasedRoom(roomType) {
  return [
    'bloomberg-ib-permanent',
    'bloomberg-ib-ephemeral',
    'bloomberg-ib-forum',
  ].includes(roomType);
}

export function isTransientIbBasedRoom(roomType) {
  return roomType.includes('bloomberg-ib-ephemeral');
}

export function isValidIbChatId(chatId) {
  return chatId && chatId !== 'None';
}

export function usePromptBeforeTabCloseOrRefresh(condition) {
  useEffect(() => {
    const event = 'beforeunload';
    window.addEventListener(event, promptOnCondition);
    return () => window.removeEventListener(event, promptOnCondition);

    function promptOnCondition(e) {
      if (condition) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    }
  }, [condition]);
}
