import React from 'react';
import { Accordion } from '@bbnpm/bb-ui-framework';
import MicSpeakerCam from './MicSpeakerCam';
import MicVolume from './MicVolume';
import PeerConnection from './PeerConnection';
import PlayAudioFile from './PlayAudioFile';
import SelectDevices from './SelectDevices';
import ScreenShare from './ScreenShare';
import { TestWebRTCContextProvider } from './TestWebRTCContext';

export default function TestWebRTC() {
  return (
    <TestWebRTCContextProvider>
      <Accordion
        defaultActiveKey={['selectDevice', 'micSpeakerCam', 'micVolume']}
      >
        <Accordion.Panel header='Select Devices' key='selectDevice'>
          <SelectDevices />
        </Accordion.Panel>
        <Accordion.Panel header='Microphone Speaker Camera' key='micSpeakerCam'>
          <MicSpeakerCam />
        </Accordion.Panel>
        <Accordion.Panel header='Microphone' key='micVolume'>
          <MicVolume />
        </Accordion.Panel>
        <Accordion.Panel header='Speaker'>
          <PlayAudioFile />
        </Accordion.Panel>
        <Accordion.Panel header='Peer Connection'>
          <PeerConnection />
        </Accordion.Panel>
        <Accordion.Panel header='Screen Share'>
          <ScreenShare />
        </Accordion.Panel>
      </Accordion>
    </TestWebRTCContextProvider>
  );
}
