import { TitleBar, Footer, Content, EmptyState } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';
import { device } from '../common/mediaQuery';
import { HOME_PATH } from '../common/paths';
import { Variant } from '@bbnpm/bb-ui-framework/EmptyState/EmptyState.types';

export function PageContainer({ children }: { children: any }) {
  return (
    <>
      <StyledTitleBar productName='ROOM'>
        <TitleBar.Links>
          <SkipLink>
            <a href='#log-in-button'>Skip to Log in</a>
          </SkipLink>
        </TitleBar.Links>
      </StyledTitleBar>
      <StyledContent>{children}</StyledContent>
      <Footer copyright={new Date().getFullYear()} />
    </>
  );
}

export function InfoPage({
  variant,
  title,
  description,
  buttonText,
  linkText,
}: {
  variant?: Variant;
  title?: string;
  description?: string;
  buttonText?: string;
  linkText?: string;
}): JSX.Element {
  return (
    <PageContainer>
      <EmptyState
        title={title}
        variant={variant as any}
        size='large'
        description={description}
        actions={
          buttonText
            ? [
                {
                  onClick: () => (window.location.href = HOME_PATH),
                  children: <span>{buttonText}</span>,
                  kind: 'primary',
                },
              ]
            : undefined
        }
        links={linkText ? [<a href={HOME_PATH}>{linkText}</a>] : undefined}
      />
    </PageContainer>
  );
}

// Shrink the padding around title bar
const StyledTitleBar = styled(TitleBar)`
  padding-left: 20px;
  padding-right: 20px;
  @media ${device.tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

// For accessibility navigation
const SkipLink = styled.div`
  > a {
      position: absolute;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;
      white-space: nowrap;
      left: 5px;
      top: 5px;
    }
    > a:focus {
      font-size: 14px;
      overflow: visible;
      height: auto;
      width: auto;
      clip: auto;
      z-index: 3;
      background-color: #000;
      color: #fff;
      padding: 5px;
      outline-offset: -3px;
      outline-color: #767676;
    }
    width: 200px;
  }
`;

const StyledContent = styled(Content)`
  background-image: url(/bgHero.svg);
  background-color: ${({ theme }) => theme.colors.backgroundShades.softest};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
export default InfoPage;
