import React, { useContext, useRef, useState } from 'react';
import { TestCase, useSpeaker, TestCaseMessage } from './common';
import { TestWebRTCContext } from './TestWebRTCContext';

export default function PlayAudioFile() {
  const { speaker } = useContext(TestWebRTCContext);
  const [error, setError] = useState('');
  const audioRef = useRef(null);
  useSpeaker(speaker, audioRef, setError);

  return (
    <TestCase>
      <audio src='/ringtone_iphone.mp3' controls ref={audioRef} />
      <TestCaseMessage
        instructionParagraphs={[
          'This test your speaker.',
          'You should hear a ringtone when you play the audio.',
        ]}
        error={error}
      />
    </TestCase>
  );
}
