import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TitleBar } from '@bbnpm/bb-ui-framework';
import { Menu } from '@bbnpm/bb-ui-framework';
import { device } from '../common/mediaQuery';

export default function ScreenshareIndicator({
  isNarrow,
  remoteScreenShares,
  pinnedParticipantId,
  selfScreenShare,
  jitsiApi,
}) {
  const [pinnedScreenShare, setPinnedScreenShare] = useState({});

  usePinnedFocus(
    setPinnedScreenShare,
    remoteScreenShares,
    pinnedParticipantId,
    selfScreenShare
  );

  if (
    (!remoteScreenShares || !remoteScreenShares.length) &&
    !selfScreenShare.sharing
  ) {
    return null;
  }

  return (
    <>
      {isNarrow ? (
        <Screenshare>
          <StyledIconLink
            customIcon={<ScreenshareIcon src='/svg/ic_tv.svg' />}
            menuItems={generateMenuItems(remoteScreenShares, selfScreenShare)}
          />
        </Screenshare>
      ) : (
        <Screenshare>
          <StyledMenu
            popperOptions={{
              placement: 'bottom-end',
            }}
            hideIcon={true}
            triggerText={
              <>
                <ScreenshareIcon src='/svg/ic_tv.svg' />
                <ScreenshareFocused>
                  {pinnedScreenShare.name}
                </ScreenshareFocused>
              </>
            }
            menuItems={generateMenuItems(remoteScreenShares, selfScreenShare)}
          />
        </Screenshare>
      )}
    </>
  );

  function generateMenuItems(remoteScreenShares, selfScreenShare) {
    const menuItems = [];

    for (let i = remoteScreenShares.length - 1; i >= 0; i--) {
      const id = remoteScreenShares[i].id;
      const name = `${remoteScreenShares[i].name}'s screen`;
      const styles =
        pinnedScreenShare.id === id
          ? {
              backgroundColor: 'gray',
            }
          : {};

      menuItems.push({
        label: name,
        // Have to define key since there could be identical labels
        // BBUI Menu translates label to key
        key: id,
        style: styles,
        // Utilizing OnClick over onSelect due to onSelect doing a
        // search via label text to determine selection
        onClick: () => pinScreenShare(name, id),
      });
    }

    if (selfScreenShare.sharing) {
      const styles =
        pinnedScreenShare.id === selfScreenShare.shareID
          ? {
              backgroundColor: 'gray',
            }
          : {};

      menuItems.push({
        label: 'Your screen',
        style: styles,
        key: selfScreenShare.shareID,
        onClick: () => pinScreenShare('Your screen', selfScreenShare.shareID),
      });
    }

    return menuItems;
  }

  function pinScreenShare(name, id) {
    setPinnedScreenShare({
      name: name,
      id: id,
    });

    if (id) jitsiApi.executeCommand('pinParticipant', id);
  }
}

function usePinnedFocus(
  setPinnedScreenShare,
  remoteScreenShares,
  pinnedParticipantId,
  selfScreenShare
) {
  useEffect(() => {
    const name = remoteScreenShares.find((elem) => {
      return elem.id === pinnedParticipantId;
    })?.name;

    if (pinnedParticipantId && name) {
      setPinnedScreenShare({
        name: `${name}'s screen`,
        id: pinnedParticipantId,
      });

      return;
    }

    if (
      pinnedParticipantId &&
      selfScreenShare.shareID === pinnedParticipantId
    ) {
      setPinnedScreenShare({
        name: 'Your screen',
        id: pinnedParticipantId,
      });

      return;
    }

    setPinnedScreenShare({
      name: 'Select a screen share',
      id: pinnedParticipantId,
    });
  }, [
    setPinnedScreenShare,
    remoteScreenShares,
    pinnedParticipantId,
    selfScreenShare,
  ]);
}

const StyledIconLink = styled(TitleBar.IconLink)`
  margin: auto;
`;

const StyledMenu = styled(Menu)`
  margin: auto;
`;

const Screenshare = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    margin-left: 1rem;
  }

  .bbui-titlebar-iconlink-button {
    max-width: 20px;
    min-width: 20px;

    .bbui-titlebar-iconlink-down {
      margin: 0 auto;
    }
  }

  @media ${device.tablet} {
    margin-left: 1rem;

    .bbui-titlebar-iconlink-button {
      max-width: 32px;
      min-width: 32px;
    }
  }
`;

const ScreenshareFocused = styled.div`
  display: flex;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ScreenshareIcon = styled.img`
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;

  @media ${device.tablet} {
    margin-right: 0.25rem;
  }
`;
