import React, { useContext, useRef } from 'react';
import { TitleBar } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { logout } from '../common/api';
import { device } from '../common/mediaQuery';
import { HELP_PATH } from '../common/paths';
import HandRaiseIndicator from './HandRaiseIndicator';
import ScreenshareIndicator from './ScreenshareIndicator';
import RecordingPill from './RecordingPill';
import SpeakerPill from './SpeakerPill';
import BBUIContextMessageList from './BBUIContextMessageList';
import { getRandomHolidayIcon, getBbgHolidayName } from '../common/util';
import CreateRoom from '../home/CreateRoom';
import { GlobalLaunchState } from '../common/launchState';
import { RoomTypeLabel } from '../common/components/RoomLabelAssets';
import RoomTypeIndicator from '../common/components/RoomTypeIndicator';
import { UserContext } from '../common/user';

function TopBar({
  inRoom,
  speaker,
  roomName,
  recorder,
  recordingStatus,
  handRaisers,
  roomType,
  remoteScreenShares,
  pinnedParticipantId,
  selfScreenShare,
  jitsiApi,
  messages,
  participantsPaneOpen,
  ibOpen,
  pollsPaneOpen,
}) {
  const user = useContext(UserContext);
  const isTablet = useMediaQuery({ query: device.tablet });
  const holiday = getBbgHolidayName();
  const holidayIcon = useRef(getRandomHolidayIcon(holiday));

  // Home page
  if (inRoom === undefined) {
    return (
      <StyledTitleBar productName='ROOM' skipToId='start-meeting-container'>
        {GlobalLaunchState.isWeb && (
          <TitleBar.Links>
            {!user.restricted && user.isInternalUser && <CreateRoom />}
            <UserIconLink />
            <HelpIconLink />
          </TitleBar.Links>
        )}
      </StyledTitleBar>
    );
  }

  // Preview page
  if (!inRoom && GlobalLaunchState.isWeb) {
    return (
      <StyledTitleBar productName='ROOM' skipToId='jitsi-container'>
        <TitleBar.Links>
          <UserIconLink />
          <HelpIconLink />
        </TitleBar.Links>
      </StyledTitleBar>
    );
  }

  if (inRoom) {
    return (
      <>
        {!isTablet ? (
          <>
            <InRoomTitleBar>
              <RecordingPill
                isNarrow={false}
                recorder={recorder}
                recordingStatus={recordingStatus}
              />
              <InfoDisplay holiday={holiday}>
                <RoomTypeLabel topbar={true}>
                  <RoomTypeIndicator
                    roomType={roomType}
                    topbar={true}
                    narrow={false}
                  />
                </RoomTypeLabel>
                <RoomNameDisplay>{roomName}</RoomNameDisplay>

                {holiday && (
                  <img
                    src={holidayIcon.current}
                    alt=''
                    width='22px'
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </InfoDisplay>
              <SpeakerPill speaker={speaker} />

              <TopBarIndicators>
                <ScreenshareIndicator
                  isNarrow={false}
                  remoteScreenShares={remoteScreenShares}
                  pinnedParticipantId={pinnedParticipantId}
                  selfScreenShare={selfScreenShare}
                  jitsiApi={jitsiApi}
                />
                <HandRaiseIndicator
                  isNarrow={false}
                  handRaisers={handRaisers}
                />
              </TopBarIndicators>
            </InRoomTitleBar>
            <InRoomNotifications
              paneOpened={ibOpen || participantsPaneOpen || pollsPaneOpen}
            >
              <BBUIContextMessageList messages={messages} />
            </InRoomNotifications>
          </>
        ) : (
          <NarrowedDisplayStack>
            <>
              <NarrowedDisplay>
                <RecordingPill
                  isNarrow={true}
                  recorder={recorder}
                  recordingStatus={recordingStatus}
                />
                <RoomTypeIndicator
                  roomType={roomType}
                  topbar={true}
                  narrow={true}
                />
                <NarrowRoomNameDisplay>{roomName}</NarrowRoomNameDisplay>
                <NarrowRoomOtherDisplay>
                  <SpeakerPill speaker={speaker} />
                  <ScreenshareIndicator
                    isNarrow={true}
                    remoteScreenShares={remoteScreenShares}
                    pinnedParticipantId={pinnedParticipantId}
                    selfScreenShare={selfScreenShare}
                    jitsiApi={jitsiApi}
                  />
                  <HandRaiseIndicator
                    isNarrow={true}
                    handRaisers={handRaisers}
                  />
                </NarrowRoomOtherDisplay>
              </NarrowedDisplay>
            </>
            <ContextMessageContainer>
              <BBUIContextMessageList messages={messages} />
            </ContextMessageContainer>
          </NarrowedDisplayStack>
        )}
      </>
    );
  } else return null;
}

function UserIconLink() {
  const user = useContext(UserContext);
  return (
    <StyledIconLink
      customIcon={
        <div className='titlebar-customicon titlebar-avatar'>
          {user.userInitials}
        </div>
      }
      menuItems={[
        { label: user.fullName.toUpperCase(), readonly: true },
        TitleBar.IconLinkDivider,
        {
          label: 'Logout',
          onClick: (e) => {
            e.preventDefault();
            logout();
          },
        },
      ]}
    />
  );
}

function HelpIconLink() {
  const user = useContext(UserContext);
  return user.isInternalUser ? (
    <StyledIconLink
      customIcon={<div className='titlebar-customicon titlebar-help'>?</div>}
      text='Help'
      onClick={(e) => {
        window.open(
          `https://blinks.bloomberg.com/screens/${encodeURIComponent(
            'NOTE /ID 6916535770743570432'
          )}`,
          '_blank'
        );
      }}
    />
  ) : (
    <StyledIconLink
      customIcon={<div className='titlebar-customicon titlebar-help'>?</div>}
      text='Help'
      onClick={(e) => {
        window.open(HELP_PATH, '_blank');
      }}
    />
  );
}

// responsive (narrow width) title bar
const StyledTitleBar = styled(TitleBar)`
  @media ${device.tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${device.mobileL} {
    .bbui-titlebar-brand-wrapper {
      max-width: 50%;
      overflow: hidden;
    }

    .bbui-titlebar-brand {
      transform: scale(0.6);
      transform-origin: left;
    }
  }

  .titlebar-customicon {
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    margin: auto;
  }

  .titlebar-avatar {
    background-color: #fb754c;
    font-size: 0.7rem;
  }

  .titlebar-help {
    background-color: #ffffff;
    color: #000000;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .titlebar-create-room {
    background-color: #0062dd;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const StyledIconLink = styled(TitleBar.IconLink)`
  margin: auto;
`;

// shrinking the padding around the title bar
const InRoomTitleBar = styled.div`
  background-color: #000;
  cursor: default;
  display: flex;
  flex: 0 0 auto;
  height: 2.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  position: relative;
  justify-content: space-between;
`;

const InRoomNotifications = styled.div`
  background-color: #000;
  display: flex;
  flex: 0 0 auto;
  height: 0rem;
  align-items: center;
  padding: 0;
  position: relative;
  ${(props) => (props.paneOpened ? 'right: 316px;' : '')}
  justify-content: flex-end;
`;

// Adding formatting for smaller screens
const RoomNameDisplay = styled.div`
  align-items: center;
  font-weight: 500;
  line-height: 1.375rem;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NarrowRoomNameDisplay = styled(RoomNameDisplay)`
  max-width: none;
  height: 1.375rem;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.375rem;
  text-align: left;
`;

const NarrowRoomOtherDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.375rem;
  text-align: left;
`;

const ContextMessageContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  z-index: 5;
`;

const NarrowedDisplayStack = styled.div`
  width: 100%;
  height: 2.5rem;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  background-color: #000;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NarrowedDisplay = styled.div`
  width: 100%;
  height: 2.5rem;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  background-color: #000;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoDisplay = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  line-height: 1.375rem;
`;

const TopBarIndicators = styled.div`
  display: flex;
  width: fit-content;
`;

export default TopBar;
