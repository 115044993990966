import Div100vh from 'react-div-100vh';
import { BBUIApp, Banner } from '@bbnpm/bb-ui-framework';
import useCheckBrowser from './common/checkBrowser';
import useDetectServiceWorkers from './common/detectServiceWorkers';
import {
  useSyncTabsOnUserAuthChange,
  UserContext,
  getUserFromSessionCookie,
} from './common/user';
import { isStorageAvailable } from './common/util';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { useState } from 'react';
import { logger } from './common/logger';
import { userAgentParser } from './common/checkBrowser';

function App() {
  const [
    browserRecommendationBannerMessage,
    setBrowserRecommendationBannerMessage,
  ] = useState('');

  useCheckBrowser((browserRecommendation) =>
    setBrowserRecommendationBannerMessage(browserRecommendation)
  );

  useDetectServiceWorkers();

  useSyncTabsOnUserAuthChange();

  const user = getUserFromSessionCookie();

  logger.info(
    `User-Agent Data: ${JSON.stringify(userAgentParser.getResult())}`
  );

  if (
    user &&
    isStorageAvailable('localStorage') &&
    !window.localStorage.getItem('ROOM_PREVIOUS_LOGIN')
  ) {
    // Remember that the user has logged in at least once on this browser
    window.localStorage.setItem('ROOM_PREVIOUS_LOGIN', 'true');
  }

  return (
    <UserContext.Provider value={user}>
      <BBUIApp activeTheme='dark'>
        <Div100vh style={{ display: 'flex', flexDirection: 'column' }}>
          {browserRecommendationBannerMessage ? (
            <Banner
              kind='warning'
              message={browserRecommendationBannerMessage}
            />
          ) : (
            <></>
          )}
          <RouterProvider router={router} />
        </Div100vh>
      </BBUIApp>
    </UserContext.Provider>
  );
}

export default App;
