import { useEffect } from 'react';
import { useNotification } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export default function useBBUINotifications(
  jitsiApi: any,
  onContextMessage: Function
) {
  const notification = useNotification();
  const notificationTypeDispatch: { [type: string]: (props: any) => {} } = {
    error: (props) => notification.addError(props),
    warning: (props) => notification.addWarning(props),
    success: (props) => notification.addSuccess(props),
    info: (props) => notification.addInfo(props),
    normal: (props) => notification.addInfo(props),
  };

  useEffect(() => {
    if (jitsiApi) {
      const event = 'showBBUINotification';
      jitsiApi.addEventListener(event, _bbuiNotifications);
      return () => {
        jitsiApi.removeEventListener(event, _bbuiNotifications);
      };
    }

    function notificationAction(actionName: String) {
      if (actionName === 'Open Polls') {
        return () => {
          jitsiApi.executeCommand('bbuiNotificationPolls');
          notification.clearAll();
        };
      }
    }

    function _bbuiNotifications({
      title,
      description,
      notificationTimeout,
      notificationType,
      icon,
      customActionName,
    }: {
      title: string;
      description: string;
      notificationTimeout: number;
      notificationType: string;
      icon: string;
      customActionName: string;
    }) {
      // determine if someone is leaving or joining the room
      if (title.includes('the meeting')) {
        onContextMessage({
          id: title,
          message: title,
          icon: icon,
        });
        return;
      }

      if (title === 'Recording') {
        onContextMessage({
          id: title,
          title: title,
          message: description,
          icon: icon,
        });
        return;
      }

      // message icon indicates a jitsi chat message
      if (icon === 'message') {
        onContextMessage({
          id: description,
          message: `${title}: ${description}`,
          icon: icon,
        });
        return;
      }

      let notificationProps;
      const message = title;

      // Some notifications call for a link to open a pane such as polls
      if (customActionName) {
        notificationProps = {
          message: (
            <span>
              {message}
              <AClickable onClick={notificationAction(customActionName)}>
                {customActionName}
              </AClickable>
            </span>
          ),
          timeout: notificationTimeout || undefined,
          details: description,
        };
      } else {
        notificationProps = {
          message: message,
          timeout: notificationTimeout || 5000,
          details: description,
        };
      }

      notificationTypeDispatch[notificationType](notificationProps);
    }
  });
}

const AClickable = styled.a`
  cursor: pointer;
`;
