import { useEffect, useCallback, useState, useContext } from 'react';
import { UserContext } from '../common/user';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  HorizontalPane,
  Tooltip,
  useNotification,
} from '@bbnpm/bb-ui-framework';
import ChevronDownIcon from '@bloomberg/web-icons-react/ChevronDown';
import ChevronUpIcon from '@bloomberg/web-icons-react/ChevronUp';
import { PanelHeader } from '../common/components/PanelHeader';
import { device } from '../common/mediaQuery';

dayjs.extend(utc);

const CONTAINER_HEADER_OFFSET = 53;
const HORIZONTAL_PANE_OFFSET = 32;

// refer to
// https://bbwc-showcase.dev.bloomberg.com/integration/frontend-integration-example
// https://bbgithub.dev.bloomberg.com/svadiyatar/task_mobile/blob/meeting_bbwc/WebSite/client/views/react/components/MeetingComponent.js
function Appt() {
  const user = useContext(UserContext);
  const notification = useNotification();

  const [isExpanded, setExpanded] = useState(false);

  const bootstrap = useCallback(() => {
    if (!window.bbwc) {
      return;
    }

    const defaultStart = dayjs()
      .subtract(15, 'minutes')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const fullDayStart = dayjs()
      .startOf('day')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    window.bbwc.globalSettings.bootstrap('appt', {
      mode: 'dark',
      calendarUUID: parseInt(user.uuid),
      sessionId: user.sessionId,
      schedule: {
        startDateTimeUtc: isExpanded ? fullDayStart : defaultStart,
        endDateTimeUtc: dayjs()
          .endOf('day')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
      },
      layout: {
        hideFooter: true,
        hideHeader: true,
      },
      behavior: {
        hideDetailsOfPrivateMeeting: false,
        openLinkInSameTab: {
          rooms: true,
        },
      },
      meetingFilters: {
        hideWholeDay: true,
        hideCancelled: true,
      },
      onError: () => {
        notification.addWarning({
          message: `Failed to refresh appointments`,
          timeout: 5000,
        });
      },
    });
  }, [user, isExpanded]);

  useEffect(() => {
    bootstrap();
    window.addEventListener('focus', bootstrap);
    return () => window.removeEventListener('focus', bootstrap);
  }, [bootstrap, isExpanded]);

  return (
    <>
      <Container>
        <StyledPanelHeader>
          {isExpanded ? 'All Appointments' : 'Upcoming Appointments'}
        </StyledPanelHeader>
        <Tooltip
          placement='bottom'
          content={
            isExpanded ? 'See upcoming appointments' : 'See all appointments'
          }
          appendTo='parent'
        >
          <div style={{ padding: '8px 8px 0px 8px' }}>
            <HorizontalPane
              height={16}
              style={{
                backgroundColor: 'rgb(35, 35, 35)',
                textAlign: 'center',
              }}
              className='expandMeeting'
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </HorizontalPane>
          </div>
        </Tooltip>
        <ApptContainer id='appt' />
      </Container>
    </>
  );
}

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

const ApptContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: calc(
    100% - ${CONTAINER_HEADER_OFFSET}px - ${HORIZONTAL_PANE_OFFSET}px
  );
  overflow-y: auto;
  @media ${device.tablet} {
    height: calc(100% - ${HORIZONTAL_PANE_OFFSET}px);
  }
`;

const StyledPanelHeader = styled(PanelHeader)`
  @media ${device.tablet} {
    display: none;
  }
`;

export default Appt;
