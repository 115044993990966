import React from 'react';
import styled from 'styled-components';

export default function Help() {
  return (
    // The content should be in sync with HELP ROOM<GO>
    <Container>
      <Title>Bloomberg Room | ROOM&lt;GO&gt;</Title>
      <StyledP>
        ROOM &lt;GO&gt; launches the Bloomberg Room web application, which
        allows you to hold voice and video conferences and share your screen
        with other Bloomberg users. Bloomberg Room is integrated with the
        Bloomberg Terminal®, so you can start a new conference from IB with just
        one click.
      </StyledP>
      <StyledImg src='/help/1.png' />
      <h3>Quick Start</h3>
      <StyledP>
        1. At the top-right of an IB chat window, click the Video Camera icon,
        then select <b>Join Conference</b>.
      </StyledP>
      <StyledImg src='/help/2.png' />
      <IndentP>
        <Note>Note:</Note> This method invites the IB chat participant(s) to the
        conference.
      </IndentP>
      <IndentP>
        If you are the recipient of a Bloomberg Room invite in IB, click{' '}
        <b>Click to Join</b>.
      </IndentP>
      <StyledImg src='/help/3.png' />
      <StyledP>
        2. On the Bloomberg Room preview page that launches in your web browser,
        click <b>Join Conference</b>.
      </StyledP>
      <StyledImg src='/help/3.png' />
      <StyledP>
        3. Click the Chat Bubble icon to open the IB chat in Bloomberg Room.
      </StyledP>
      <StyledImg src='/help/3.png' />
      <IndentP>
        <Note>Hint:</Note> The conference toolbar at the bottom is hidden while
        you are in the conference. To resurface it, move your mouse over the
        video tiles.
      </IndentP>
    </Container>
  );
}

const Container = styled.div`
  padding: 30px;
`;
const StyledImg = styled.img`
  width: calc(100% - 120px);
  max-width: 500px;
  margin: 30px;
`;
const Title = styled.h1`
  color: orange;
`;
const StyledP = styled.p`
  line-height: 1.5;
`;
const Note = styled.b`
  color: orange;
`;
const IndentP = styled(StyledP)`
  margin-left: 30px;
`;
