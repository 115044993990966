import React, { useContext } from 'react';
import { Button, useNotification } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { createRoom as _createRoom } from '../common/api';
import { UserContext } from '../common/user';

export default function CreateRoom() {
  const user = useContext(UserContext);
  const notification = useNotification();
  const navigate = useNavigate();

  return (
    <div id='start-meeting-container'>
      <StartConferenceButton
        onClick={createRoom}
        kind='tertiary'
        aria-label='New Meeting'
      >
        New Meeting
      </StartConferenceButton>
    </div>
  );

  async function createRoom() {
    const res = await _createRoom(user.loginJwt);
    if (res.error) notification.addError({ message: res.error });
    else navigate(`/rooms/${res.roomId}`);
  }
}

const StartConferenceButton = styled(Button)`
  text-transform: none;
`;
