import React, { useContext, useEffect, useState } from 'react';
import { placeClickToDialCall, getPhoneNumbers } from '../common/api';
import styled from 'styled-components';
import { PhoneNumberDetail, FormattedPhoneNumberProps } from '../types';
import { Modal, useNotification, Button } from '@bbnpm/bb-ui-framework';
import { UserContext } from '../common/user';

interface Props {
  roomId: string;
  isOpen: boolean;
  openDialInModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const phoneNumbersCache: { [key: string]: PhoneNumberDetail[] } = {};

export default function DialInModal({
  roomId,
  isOpen,
  openDialInModal,
}: Props) {
  const user = useContext(UserContext);
  const notification = useNotification();
  const phoneNumbers = useGetPhoneNumbers(isOpen, roomId);

  if (isOpen && phoneNumbers.length === 0) return null;
  return (
    <DialInPopup
      isOpen={isOpen && phoneNumbers.length > 0}
      title='Join via Phone'
      contentLabel='Join via Phone'
      onClose={() => openDialInModal(false)}
      portalContainerId='root'
      primaryActions={
        <>
          {user?.phoneNumber && (
            <Button kind='primary' onClick={() => placeCall()}>
              Call Me
            </Button>
          )}
          <Button kind='secondary' onClick={() => openDialInModal(false)}>
            Close
          </Button>
        </>
      }
    >
      {phoneNumbers.map((p) => (
        <FormattedPhoneNumber
          key={p.id}
          type={p.id}
          number={p.number}
          ext={p.ext}
        />
      ))}
      {phoneNumbers.length > 0 && (
        <>
          <div>Ext: {phoneNumbers[0].ext}#</div>
          {/* This will be uncommented when pins become available */}
          {/* <Footnote>Conference PIN: {phoneNumbers[0].pin}#</Footnote> */}
          <Divider />
          <div>
            <div> Or use Click to Dial to call my phone:</div>
            <div>{user?.fullName}</div>
            <div>{user?.phoneNumber}</div>
          </div>
        </>
      )}
    </DialInPopup>
  );

  async function placeCall() {
    if (!user) {
      return;
    }
    const res = await placeClickToDialCall(
      user.loginJwt,
      user.phoneNumber,
      `${phoneNumbers[0]?.number}`,
      `${phoneNumbers[0]?.ext}`
    );
    displayCallStatusNotification(res, notification, user.phoneNumber);
    openDialInModal(false);
  }

  function useGetPhoneNumbers(isOpen: boolean, roomId: string) {
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberDetail[]>([]);

    useEffect(() => {
      getDialInNumbers();
      async function getDialInNumbers(): Promise<void> {
        if (!user) {
          return Promise.reject();
        }
        // TODO: Simplify this logic and add test
        if (isOpen) {
          if (phoneNumbersCache[roomId]) {
            // Do not call setPhoneNumbers if we are already have the correct phoneNumbers
            if (
              JSON.stringify(phoneNumbersCache[roomId]) !==
              JSON.stringify(phoneNumbers)
            ) {
              setPhoneNumbers(phoneNumbersCache[roomId]);
            }
          } else {
            const phoneNumbersResponse = await getPhoneNumbers(
              user.loginJwt,
              roomId
            );

            if (phoneNumbersResponse.length === 0) {
              notification.addError({
                message: <Label>No Dial-in Information</Label>,
              });
              // Only reset the phone number state if it is not empty to prevent an infinite loop.
              if (phoneNumbers.length) {
                setPhoneNumbers([]);
              }
            } else {
              // Store phone numbers of the given room id
              phoneNumbersCache[roomId] = phoneNumbersResponse;
              setPhoneNumbers(phoneNumbersResponse);
            }
          }
        }
      }
    }, [isOpen, roomId, phoneNumbers]);
    return phoneNumbers;
  }
}

function displayCallStatusNotification(
  isCallPlaced: boolean,
  notification: any,
  phoneNumber: string
) {
  isCallPlaced
    ? notification.addInfo({
        message: <Label>{`Calling ${phoneNumber} for meeting audio`}</Label>,
      })
    : notification.addError({
        message: <Label>{`Call was not placed to ${phoneNumber}`}</Label>,
      });
}

const FormattedPhoneNumber: React.FC<FormattedPhoneNumberProps> = ({
  type,
  number,
  ext,
}) => (
  <div>
    {type}:{' '}
    <a target='_parent' href={`tel:${number},,,${ext}%23`}>
      {number}
    </a>
  </div>
);

const Label = styled.div`
  display: inherit;
`;

const DialInPopup = styled(Modal)`
  width: 25rem;

  a:link,
  a:visited,
  a:hover {
    color: #ffffff;
  }
`;
const Divider = styled.div`
  height: 1rem;
  border-top: solid 1px #646464;
  width: initial;
  margin-top: 1rem;
`;
