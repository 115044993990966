export async function fetchWithTimeout(url, options) {
  const { timeout = 3000 } = options;
  const controller = new AbortController();

  const timeoutId = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(url, { ...options, signal: controller.signal });
  clearTimeout(timeoutId);

  return response;
}

export async function fetchWithRetryAndTimeout({
  url,
  fetchOptions,
  timeout,
  maxAttempts,
  retryCondition,
  delayRetry,
  onRetry,
  onAborted,
  onAllRetriesFailed,
}) {
  return await _fetchRetryTimeout({
    ...arguments[0],
    attempt: 1,
  });
}

async function _fetchRetryTimeout({
  url,
  fetchOptions,
  timeout,
  attempt,
  maxAttempts,
  retryCondition,
  onRetry,
  onAborted,
  delayRetry,
  onAllRetriesFailed,
}) {
  if (attempt > maxAttempts) return onAllRetriesFailed();

  const retryOptions = {
    ...arguments[0],
    attempt: attempt + 1,
  };

  const ctrl = new AbortController();
  setTimeout(() => ctrl.abort(), timeout);

  let res;
  try {
    res = await fetch(url, { ...fetchOptions, signal: ctrl.signal });
  } catch (e) {
    if (e.name === 'AbortError') {
      onAborted(attempt);
    }
    onRetry({ error: e, attempt });
    await wait(delayRetry(attempt));
    return await _fetchRetryTimeout(retryOptions);
  }

  if (retryCondition(res)) {
    onRetry({ response: res, attempt });
    await wait(delayRetry(attempt));
    return await _fetchRetryTimeout(retryOptions);
  } else {
    return res;
  }
}

async function wait(ms) {
  return new Promise((r) => setTimeout(r, ms));
}
