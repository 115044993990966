import styled from 'styled-components';
import { Accordion } from '@bbnpm/bb-ui-framework';

export const BorderlessAccordion = styled(Accordion)`
  border-bottom: none;
  border-top: none;
`;

export const BorderlessAccordionPanel = styled(Accordion.Panel)`
  border-bottom: none;
  background-color: black;
  padding: 0 8px;
`;
