import { useEffect, useCallback } from 'react';
import { HOME_PATH } from '../common/paths';
import { isStorageAvailable } from '../common/util';

function LandingPage() {
  const bootstrap = useCallback(() => {
    window.bbwc.landingPage.bootstrap('landing-page', {
      caller: 'room',
      productTitle: 'Bloomberg ROOM',
      productSignInDescription:
        'Welcome to Bloomberg ROOM, a secure meeting platform powered by Bloomberg.',
      productSignInRoute: HOME_PATH,
      marketingContent: {},
      footerConfig: {
        links: {},
      },
    });

    if (
      isStorageAvailable('localStorage') &&
      !window.localStorage.getItem('ROOM_VISITED_LANDING')
    ) {
      // Remember that the user has seen landing page at least once on this browser
      window.localStorage.setItem('ROOM_VISITED_LANDING', 'true');
    }
  }, []);

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);

  return <div id='landing-page' />;
}

export default LandingPage;
