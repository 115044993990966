import React, { useEffect, useState, useContext } from 'react';
import { TestCaseMessage, TestCase } from './common';
import { TestWebRTCContext } from './TestWebRTCContext';

export default function MicVolume() {
  const { stream } = useContext(TestWebRTCContext);

  const [error, setError] = useState('');
  const [volume, setVolume] = useState(0);

  useGetMicVol(stream, setVolume, setError);

  return (
    <TestCase>
      <meter value={volume} max='0.5' high='0.25' />
      <TestCaseMessage
        instructionParagraphs={[
          'This tests your micropohone.',
          'You should see the volume change as you speak.',
        ]}
        error={error}
      />
    </TestCase>
  );
}

function useGetMicVol(stream, setVolume, setError) {
  useEffect(() => {
    if (stream) {
      getMicVol();
    }

    async function getMicVol() {
      try {
        const audioContext = new AudioContext();
        await audioContext.audioWorklet.addModule('audio-volume-processor.js');
        const streamSrc = audioContext.createMediaStreamSource(stream);
        const node = new AudioWorkletNode(audioContext, 'volume');
        node.port.onmessage = (event) => {
          setVolume(event.data.volume || 0);
        };
        streamSrc.connect(node).connect(audioContext.destination);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }
  }, [stream, setVolume, setError]);
}
