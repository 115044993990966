import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BorderlessAccordion,
  BorderlessAccordionPanel,
} from '../common/components/BorderlessAccordion';
import { BorderlessListItem } from '../common/components/BorderlessListItem';
import { PanelHeader } from '../common/components/PanelHeader';
import { device } from '../common/mediaQuery';
import { getInitials } from '../common/util';
import { getRoomParticipants, getIbChatMembers } from '../common/api';
import { logger } from '../common/logger';
import { isIbBasedRoom } from './util';

export default function ParticipantListInPreview({ user, roomAuth }) {
  const [chatMembers, setChatMembers] = useState([]);
  const [roomParticipants, setRoomParticipants] = useState([]);
  const [activeKeys, setActiveKeys] = useState(['inConferenceGroup']);

  const togglePanel = (key) => {
    let newActiveKeys = [...activeKeys];

    if (newActiveKeys.includes(key)) {
      newActiveKeys = newActiveKeys.filter((k) => k !== key);
    } else {
      newActiveKeys.push(key);
    }
    setActiveKeys(newActiveKeys);
  };

  useUpdateParticipants(roomAuth, setRoomParticipants);
  useUpdateIbChatMembers(user, roomAuth, setChatMembers);

  // Compute participants not in meeting based on known ROOM participants & IB Chat members (if this was an IB-based meeting)
  // FIXME: Update Prosody module to return ROOM participant UUIDs so that the filter function can be based on UUIDs and not names
  const participantNames = roomParticipants.map((p) => p.display_name);
  const chatMembersNotInRoom = chatMembers
    .filter((p) => participantNames.indexOf(p[0]) < 0)
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <Panel>
      <PanelHeader>Meeting Participants</PanelHeader>
      <BorderlessAccordion
        multiple={true}
        activeKey={activeKeys}
        onChange={togglePanel}
      >
        <BorderlessAccordionPanel
          key='inConferenceGroup'
          groupId='inConferenceGroup'
          header={`In Meeting [${roomParticipants.length}]`}
        >
          {roomParticipants
            .map((p) => [p.display_name, p.user_initials])
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((n, i) => (
              <BorderlessListItem
                key={`inConference ${i}`}
                label={<ListItemLabel displayText={n[0]} userInitials={n[1]} />}
              />
            ))}
        </BorderlessAccordionPanel>
        <BorderlessAccordionPanel
          key='notConferenceGroup'
          groupId='notConferenceGroup'
          header={`Not In Meeting [${chatMembersNotInRoom.length}]`}
        >
          {chatMembersNotInRoom.map((n, i) => (
            <BorderlessListItem
              key={`notInConference ${i}`}
              label={<ListItemLabel displayText={n[0]} userInitials={n[1]} />}
            />
          ))}
        </BorderlessAccordionPanel>
      </BorderlessAccordion>
    </Panel>
  );
}

function useUpdateParticipants(roomAuth, setRoomParticipants) {
  useEffect(() => {
    if (roomAuth) {
      updateParticipants();
      const interval = setInterval(updateParticipants, 15000);
      return () => clearInterval(interval);
    }

    async function updateParticipants() {
      const participants = await getRoomParticipants(
        roomAuth.room.roomId,
        roomAuth.jwt
      );
      if (!participants.error) {
        setRoomParticipants(
          // Filter out recording service participant
          participants.filter((p) => p.display_name && p.email)
        );
      } else {
        logger.error(
          `(Preview Participants) Room failed to get participants, Error = ${participants.error}`
        );
      }
    }
  }, [roomAuth, setRoomParticipants]);
}

function useUpdateIbChatMembers(user, roomAuth, setChatMembers) {
  useEffect(() => {
    if (
      user &&
      roomAuth?.room?.ibChatId &&
      isIbBasedRoom(roomAuth.room?.roomType)
    ) {
      updateIbChatMembers();
    }

    async function updateIbChatMembers() {
      const chatMembers = await getIbChatMembers(
        roomAuth.room.ibChatId,
        user.loginJwt
      );

      if (!chatMembers.error) {
        setChatMembers(
          chatMembers
            .filter((m) => m.name && m.status !== 'REMOVED')
            .map((m) => [m.name, m.initials])
        );
      } else {
        logger.error(
          `(Preview Participants) Room IB chat ID [${roomAuth.room.ibChatId}] Failed to get IB chat members, Error = ${chatMembers.error}`
        );
      }
    }
  }, [user, roomAuth, setChatMembers]);
}

function ListItemLabel({ displayText, userInitials }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: '#373737',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            fontSize: '12px',
          }}
        >
          {userInitials ?? getInitials(displayText)}
        </div>
      </div>
      <span style={{ marginRight: '20px' }}></span>
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {displayText}
      </span>
    </div>
  );
}

const Panel = styled.div`
  display: flex;
  background-color: black;
  color: white;
  height: 100%;
  width: 75%;
  flex-flow: column;
  top: 0;
  overflow-x: hidden;
  @media ${device.tablet} {
    margin-top: 0px;
    width: 100%;
  }
`;
