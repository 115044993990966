import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@bbnpm/bb-ui-framework';
import {
  useSpeaker,
  TestCase,
  TestCaseMessage,
  VideoWithButton,
  useRegisterToggleOfTestUsingSpeaker,
  useStopOtherTestsUsingSpeaker,
} from './common';
import { TestWebRTCContext } from './TestWebRTCContext';

export default function PeerConnection() {
  const { stream, speaker, togglesOfTestsUsingSpeaker } =
    useContext(TestWebRTCContext);

  const [error, setError] = useState('');
  const remoteVideoRef = useRef(null);
  const [testStarted, setTestStarted] = useState(false);

  useSpeaker(speaker, remoteVideoRef, setError);
  useRegisterToggleOfTestUsingSpeaker(
    togglesOfTestsUsingSpeaker,
    setTestStarted
  );

  useStopOtherTestsUsingSpeaker(
    togglesOfTestsUsingSpeaker,
    setTestStarted,
    testStarted
  );
  useDetachStream(testStarted, remoteVideoRef);
  const [local, remote] = useCreatePeer(testStarted);
  useConnect(local, remote, stream, remoteVideoRef, setError);

  return (
    <TestCase>
      <VideoWithButton>
        <Button onClick={() => setTestStarted(!testStarted)}>
          {testStarted ? 'Hangup' : 'Call'}
        </Button>
        <video ref={remoteVideoRef} autoPlay />
      </VideoWithButton>
      <TestCaseMessage
        instructionParagraphs={[
          'This is to test peer connection in the same tab.',
          'Click "Call" button and you should see your camera view and hear yourself speaking as the remote side.',
          'Please use your headphone to prevent echos.',
          'Note: starting this test will stop other tests using your speaker.',
        ]}
        error={error}
      />
    </TestCase>
  );
}

function useConnect(local, remote, localStream, remoteVideoRef, setError) {
  useEffect(() => {
    if (local && remote && localStream && remoteVideoRef) {
      local.addEventListener('icecandidate', addRemoteIceCandidate);
      remote.addEventListener('icecandidate', addLocalIceCandidate);
      remote.addEventListener('track', attachRemoteVideoStream);
      addLocalStream();
      createOffer();

      return () => {
        local.removeEventListener('icecandidate', addRemoteIceCandidate);
        remote.removeEventListener('icecandidate', addLocalIceCandidate);
        remote.removeEventListener('track', attachRemoteVideoStream);
      };
    }

    function addLocalIceCandidate(e) {
      addIceCandidate(e, local);
    }

    function addRemoteIceCandidate(e) {
      addIceCandidate(e, remote);
    }

    function addLocalStream() {
      localStream
        .getTracks()
        .forEach((track) => local.addTrack(track, localStream));
    }

    function attachRemoteVideoStream(e) {
      if (
        remoteVideoRef.current &&
        remoteVideoRef.current.srcObject !== e.streams[0]
      ) {
        remoteVideoRef.current.srcObject = e.streams[0];
      }
    }

    function addIceCandidate(event, peerConnection) {
      try {
        peerConnection.addIceCandidate(event.candidate);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }

    async function createOffer() {
      try {
        const offer = await local.createOffer({
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1,
        });
        handleCreateOfferSuccess(offer);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }

    async function handleCreateOfferSuccess(desc) {
      try {
        await local.setLocalDescription(desc);
      } catch (e) {
        setError(e);
        console.error(e);
      }
      try {
        await remote.setRemoteDescription(desc);
      } catch (e) {
        setError(e);
        console.error(e);
      }
      try {
        const answer = await remote.createAnswer();
        await handleCreateAnswerSuccess(answer);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }

    async function handleCreateAnswerSuccess(desc) {
      try {
        await remote.setLocalDescription(desc);
      } catch (e) {
        setError(e);
        console.error(e);
      }
      try {
        await local.setRemoteDescription(desc);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }
  }, [local, remote, localStream, remoteVideoRef, setError]);
}

function useCreatePeer(testStarted) {
  const [local, setLocal] = useState(null);
  const [remote, setRemote] = useState(null);

  useEffect(() => {
    if (testStarted) {
      const config = {};
      const local = new RTCPeerConnection(config);
      const remote = new RTCPeerConnection(config);

      setLocal(local);
      setRemote(remote);
    }
  }, [testStarted]);

  useEffect(() => {
    if (!testStarted) {
      clearConnection();
    }

    function clearConnection() {
      if (local) {
        local.close();
        setLocal(null);
      }
      if (remote) {
        remote.close();
        setRemote(null);
      }
    }
  }, [testStarted, local, remote]);

  return [local, remote];
}

function useDetachStream(testStarted, videoRef) {
  useEffect(() => {
    if (!testStarted && videoRef.current) {
      videoRef.current.srcObject = null;
    }
  }, [testStarted, videoRef]);
}
