import styled from 'styled-components';

export const PanelHeader = styled.header`
  padding: 16px 8px;
  font-size: 18px;
  font-weight: 500;
  margin: 0 8px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.backgroundShades.strong};
`;
