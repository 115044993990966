import { useEffect } from 'react';
import { logger } from './logger';

// Hook to detect the presence of service worker(s) installed by older versions of Jitsi Meet or some other component.
function useDetectServiceWorkers() {
  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      // No support for service workers.
      logger.info('Browser does not support service workers.');
      return;
    }

    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        if (registrations && registrations.length) {
          registrations.forEach((registration) => {
            if (registration.installing) {
              logger.info(
                `Service worker installing with script ${registration.installing.scriptURL}`
              );
            } else if (registration.waiting) {
              logger.info(
                `Service worker waiting after installation with script ${registration.waiting.scriptURL}`
              );
            } else if (registration.active) {
              logger.info(
                `Service worker activated/activating after installation with script ${registration.active.scriptURL}`
              );
            }
          });
        } else {
          logger.info('No service worker registrations found.');
        }
      })
      .catch((err) => {
        logger.error(
          `Unable to process service worker registrations. Error = ${err}`
        );
      });
  }, []);
}

export default useDetectServiceWorkers;
