import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getUserInvitations, createInvitations } from './api';
import { logger } from './logger';
import { UserType, Invitation } from '../types';

dayjs.extend(utc);

export async function getSelfInvitations(
  user: UserType,
  sinceSecondsAgo: number
): Promise<Invitation[]> {
  const invitations = await getUserInvitations(
    user.loginJwt,
    secondsAgoToIsoDate(sinceSecondsAgo)
  );

  if ('error' in invitations) {
    logger.error('Failed to get invitations.', invitations.error);
    return [];
  }

  const newInvitations: Invitation[] = (invitations as Invitation[]).filter(
    (invitation: Invitation) =>
      !isInvitationAlreadyFetchedInThisAppSession(invitation) &&
      !isInvitationAlreadyHandledByUser(invitation)
  );

  invitationsAlreadyFetchedInThisAppSession.push(
    ...(invitations as Invitation[])
  );

  return newInvitations;
}

export function markInvitationAlreadyHandledByUser(
  invitation: Invitation
): void {
  const handledInvitations: Invitation[] = getInvitationsAlreadyHandledByUser();
  handledInvitations.push(invitation);
  updateInvitationsAlreadyHandledByUserInLocalStorage(handledInvitations);
}

function isInvitationAlreadyHandledByUser(invitation: Invitation): boolean {
  return (
    getInvitationsAlreadyHandledByUser().find((inviHandled) =>
      sameInvitation(inviHandled, invitation)
    ) !== undefined
  );
}

function isInvitationAlreadyFetchedInThisAppSession(
  invitation: Invitation
): boolean {
  return (
    invitationsAlreadyFetchedInThisAppSession.find((invitShown) =>
      sameInvitation(invitShown, invitation)
    ) !== undefined
  );
}

export async function inviteUserToRoom(
  uuid: string,
  roomId: string,
  loginJwt: string
) {
  return await createInvitations(
    roomId,
    [{ bpi: `bpi:bb_uuid:${uuid}` }],
    loginJwt
  );
}

export function secondsAgoToIsoDate(sinceSecondsAgo: number): string {
  return dayjs.utc().subtract(sinceSecondsAgo, 'seconds').toISOString();
}

function getInvitationsAlreadyHandledByUser(): Invitation[] {
  const invitationsAlreadyHandledByUserStringified =
    window.localStorage.getItem(
      INVITATIONS_ALREADY_HANDLED_BY_USER_LOCAL_STORAGE_ITEM_NAME
    );

  if (!invitationsAlreadyHandledByUserStringified) {
    return [];
  }

  try {
    return JSON.parse(invitationsAlreadyHandledByUserStringified);
  } catch (e) {
    logger.error(
      'Failed to parse invitations already handled by user in local storage',
      invitationsAlreadyHandledByUserStringified
    );
    return [];
  }
}

function sameInvitation(a: Invitation, b: Invitation): boolean {
  return (
    a.roomId === b.roomId &&
    a.invitedByName === b.invitedByName &&
    a.createdAt === b.createdAt
  );
}

function updateInvitationsAlreadyHandledByUserInLocalStorage(
  invitations: Invitation[]
): void {
  try {
    const invitationsStringified = JSON.stringify(invitations);
    window.localStorage.setItem(
      INVITATIONS_ALREADY_HANDLED_BY_USER_LOCAL_STORAGE_ITEM_NAME,
      invitationsStringified
    );
  } catch (e) {
    logger.error('Failed to stringify invitations', invitations);
  }
}

const invitationsAlreadyFetchedInThisAppSession: Invitation[] = [];
const INVITATIONS_ALREADY_HANDLED_BY_USER_LOCAL_STORAGE_ITEM_NAME =
  'handled invitations';
