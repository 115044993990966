import React from 'react';
import { ContextualMessage } from '@bbnpm/bb-ui-framework';
import TalkBubbleIcon from '@bloomberg/web-icons-react/TalkBubble';
import styled from 'styled-components';

export default function CustomContextualMessage({
  id,
  title = null,
  message,
  icon,
}: {
  id: number;
  title: string | null;
  message: string;
  icon: string | null;
}) {
  return (
    <MessageListItem key={id}>
      <ContextualMessage
        key={id}
        message={
          <StyledIconLink>
            <div>
              {icon === 'message' ? (
                <Header>
                  <MessageIcon />
                  <MessageHeader>Message</MessageHeader>
                </Header>
              ) : null}
              {title && <MessageHeader>{title}</MessageHeader>}
            </div>
            <MessageContainer>{message}</MessageContainer>
          </StyledIconLink>
        }
      />
    </MessageListItem>
  );
}

const StyledIconLink = styled.div`
  margin: auto;
  display: table;
  text-overflow: ellipsis;
  position: relative;
`;

const MessageIcon = styled(TalkBubbleIcon)`
  height: 20px;
`;

const Header = styled.div`
  display: flex;
`;

const MessageHeader = styled.div`
  text-align: left;
  margin: 0;
`;

const MessageContainer = styled.div`
  word-wrap: break-word;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MessageListItem = styled.li`
  float: left;
  margin-top: 3px;
  margin-left: auto;
  margin-right: 15px;
  width: 300px;
`;
