import { useEffect } from 'react';
import { UAParser } from 'ua-parser-js';

export const userAgentParser = new UAParser(navigator.userAgent);

function checkBrowser(): string | void {
  const os = userAgentParser.getOS().name;

  // Handles iPhones and iPads requesting the desktop version of website
  // iPad does this by default
  if ((os === 'iOS' || os === 'Mac OS') && isMobile()) {
    if (!userAgentParser.getBrowser().name?.includes('Safari'))
      return 'Safari is recommended for the best experience on iPhone and iPad.';

    return '';
  }

  if (userAgentParser.getEngine().name !== 'Blink') {
    return 'Chrome is recommended for the best experience on Windows and macOS.';
  }

  return '';
}

function isMobile(): boolean {
  return (
    userAgentParser.getDevice().type === 'mobile' ||
    userAgentParser.getDevice().type === 'tablet' ||
    navigator.maxTouchPoints > 2
  );
}

function useCheckBrowser(
  onNotRecommendedBrowser: (browserRecommendation: string) => void
) {
  useEffect(() => {
    const browserRecommendation = checkBrowser();
    if (browserRecommendation) onNotRecommendedBrowser(browserRecommendation);
  }, [onNotRecommendedBrowser]);
}

export default useCheckBrowser;
