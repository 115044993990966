import { useEffect } from 'react';
import styled from 'styled-components';

export function useSpeaker(speaker, videoOrAudioRef, setError) {
  useEffect(() => {
    if (speaker && videoOrAudioRef.current) {
      try {
        videoOrAudioRef.current.setSinkId(speaker.deviceId);
      } catch (e) {
        setError(e);
        console.error(e);
      }
    }
  }, [speaker, videoOrAudioRef, setError]);
}

export function useVideoStream(stream, videoRef, testStarted) {
  useEffect(() => {
    if (testStarted && stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [stream, videoRef, testStarted]);
}

export function useRegisterToggleOfTestUsingSpeaker(
  togglesOfTestsUsingSpeaker,
  setTestStarted
) {
  useEffect(() => {
    if (
      togglesOfTestsUsingSpeaker.current &&
      !togglesOfTestsUsingSpeaker.current.find(
        (toggle) => toggle === setTestStarted
      )
    ) {
      togglesOfTestsUsingSpeaker.current.push(setTestStarted);
    }
  }, [togglesOfTestsUsingSpeaker, setTestStarted]);
}

export function useStopOtherTestsUsingSpeaker(
  togglesOfTestsUsingSpeaker,
  setTestStarted,
  testStarted
) {
  useEffect(() => {
    if (testStarted && togglesOfTestsUsingSpeaker.current) {
      togglesOfTestsUsingSpeaker.current
        .filter((toggle) => toggle !== setTestStarted)
        .forEach((toggle) => toggle(false));
    }
  }, [togglesOfTestsUsingSpeaker, setTestStarted, testStarted]);
}

export const TestCase = styled.div`
  display: flex;
  flex-wrap: wrap;

  > :first-child {
    flex-basis: 450px;
    margin-right: 16px;
    align-self: center;
  }

  > :nth-child(2) {
    flex: 1 1 200px;
  }
`;

export function TestCaseMessage({ instructionParagraphs, error }) {
  return (
    <div>
      {instructionParagraphs.map((p, i) => (
        <p key={i}>{p}</p>
      ))}
      <p style={{ color: 'red' }}>
        {typeof error === 'string' ? error : error.toString()}
      </p>
      {error && (
        <p style={{ color: 'red' }}>Check browser console for more details.</p>
      )}
    </div>
  );
}

export const VideoWithButton = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    width: 100px;
  }

  > :nth-child(2) {
    width: 100%;
    margin-top: 8px;
  }
`;
