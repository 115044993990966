import { RoomType, RoomTypeIcon } from './RoomLabelAssets';

function RoomTypeIndicator({ roomType, topbar, narrow }) {
  let srcIcon = '';
  let roomLabel = '';

  switch (roomType) {
    case 'adhoc-conference':
      srcIcon = '/roomtypes/ic_adhoc_room.svg';
      roomLabel = 'ROOM';
      break;
    case 'bloomberg-appt-conference':
      srcIcon = '/roomtypes/ic_appt_room.svg';
      roomLabel = 'APPT';
      break;
    case 'bloomberg-ib-ephemeral':
    case 'bloomberg-ib-permanent':
      srcIcon = '/roomtypes/ic_ib_room.svg';
      roomLabel = 'IB';
      break;
    case 'bloomberg-ib-forum':
      srcIcon = 'ib-forum-mask.svg';
      roomLabel = 'IB';
      break;
    case 'personal-conference':
      srcIcon = '/roomtypes/ic_personal_room.svg';
      roomLabel = 'Personal Room';
      break;
    default:
      return null;
  }

  if (topbar) {
    return (
      <>
        <RoomTypeIcon src={srcIcon} topbar={true} />
        {!narrow && roomLabel}
      </>
    );
  } else {
    return (
      <>
        <RoomTypeIcon src={srcIcon} topbar={false} />
        <RoomType>{roomLabel}</RoomType>
      </>
    );
  }
}

export default RoomTypeIndicator;
