import { createContext, useState, useRef } from 'react';

export const TestWebRTCContext = createContext({
  stream: null,
  setStream: () => {},
  speaker: null,
  setSpeaker: () => {},
  mic: null,
  setMic: () => {},
  cam: null,
  setCam: () => {},
  togglesOfTestsUsingSpeaker: null,
});

export function TestWebRTCContextProvider({ children }) {
  const [mic, setMic] = useState(null);
  const [cam, setCam] = useState(null);
  const [speaker, setSpeaker] = useState(null);
  const [stream, setStream] = useState(null);
  const togglesOfTestsUsingSpeaker = useRef([]);

  const contextValue = {
    stream,
    setStream,
    speaker,
    setSpeaker,
    cam,
    setCam,
    mic,
    setMic,
    togglesOfTestsUsingSpeaker,
  };

  return (
    <TestWebRTCContext.Provider value={contextValue}>
      {children}
    </TestWebRTCContext.Provider>
  );
}
