import { v4 as uuidv4 } from 'uuid';
import { getUrlParam, hasUrlParam, sanitizeUrl } from './util';

enum AppMode {
  TERMINAL = 'terminal',
  WEB = 'web',
}

enum AppEnvironment {
  DEV = 'dev',
  LOCAL = 'local',
  PROD = 'prod',
  QA = 'qa',
}

type LaunchState = {
  appInstanceId: string;
  appMode: AppMode;
  appEnv: AppEnvironment;
  bevoSessionId: string | null;
  shortLivedBssoOpenToken: string | null;
  isWeb: boolean;
  isDev: boolean;
  isLocal: boolean;
  isProd: boolean;
  isQa: boolean;
};

function getAppEnv(): AppEnvironment {
  switch (window.location.hostname) {
    case 'live.blproom.com':
      return AppEnvironment.PROD;
    case 'dev.broomtestingfactory.com':
      return AppEnvironment.DEV;
    case 'qa.broomtestingfactory.com':
      return AppEnvironment.QA;
    default:
      return AppEnvironment.LOCAL;
  }
}

function getAppMode(): AppMode {
  // Use the 'terminal' query param in the URL launched from the Terminal
  if (hasUrlParam('terminal')) {
    return AppMode.TERMINAL;
  }
  return AppMode.WEB;
}

export const GlobalLaunchState: LaunchState = Object.freeze({
  // Use the 'appInstanceId' query param in the URL launched from the Terminal, if available
  appInstanceId: getUrlParam('appInstanceId') || uuidv4(),
  appMode: getAppMode(),
  appEnv: getAppEnv(),
  // Use the 'bevoSessionId' query param in the URL launched from the Terminal, if available
  bevoSessionId: getUrlParam('bevoSessionId'),
  // Use the 'bssoOpenToken' query param in the URL launched from the Terminal, if available
  shortLivedBssoOpenToken: getUrlParam('bssoOpenToken'),
  isWeb: getAppMode() === AppMode.WEB,
  isDev: getAppEnv() === AppEnvironment.DEV,
  isLocal: getAppEnv() === AppEnvironment.LOCAL,
  isProd: getAppEnv() === AppEnvironment.PROD,
  isQa: getAppEnv() === AppEnvironment.QA,
});

// Hide sensitive information from the launched URL after establishing launch state
window.history.replaceState(null, '', sanitizeUrl(window.location.href));
