import React from 'react';
import { Token } from '@bbnpm/bb-ui-framework';
import styled, { keyframes } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { device } from '../common/mediaQuery';
import { getInitials } from '../common/util';

export default function SpeakerPill({ speaker }) {
  const isNarrowScreen = useMediaQuery({ query: device.tablet });

  return (
    <>
      {speaker && speaker.name && (
        <DesktopToken $isNarrowScreen={isNarrowScreen}>
          <Flexbox>
            {speaker.avatar ? (
              <StyledImage src={speaker.avatar} />
            ) : (
              <StyledInitials>
                {speaker.userInitials
                  ? speaker.userInitials
                  : getInitials(speaker.name)}
              </StyledInitials>
            )}
            <StyledText>{speaker.name} is speaking</StyledText>
          </Flexbox>
        </DesktopToken>
      )}
    </>
  );
}

const Flexbox = styled.div`
  display: flex;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const DesktopToken = styled(Token)`
  margin: ${({ $isNarrowScreen }) => ($isNarrowScreen ? '0' : 'auto')};
  position: ${({ $isNarrowScreen }) =>
    $isNarrowScreen ? 'static' : 'absolute'};
  left: 50%;
  top: 0.25rem;
  transform: ${({ $isNarrowScreen }) =>
    $isNarrowScreen ? 'translate(0px)' : 'translate(-50%)'};
  height: 2rem;
  border-radius: 1rem;
  padding: 0 0.75rem 0 0.25rem;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 0.4s
    cubic-bezier(0.4, 0, 0.2, 1);
  transition: visibility 0.4s cubic-bezier(0, 0, 0.2, 1);
  ${({ $isNarrowScreen }) =>
    $isNarrowScreen
      ? 'background-color: #373737; display: inline-flex; width: fit-content; z-index: 1;'
      : ''}
`;

const StyledImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  object-fit: cover;
  top: 0.25rem;
  margin: auto;
`;

const StyledInitials = styled.div`
  background-color: #fb754c;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  margin: auto;
`;

const StyledText = styled.div`
  padding-left: 0.5rem;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  position: relative;
  height: 2rem;
  margin: auto;
  padding-top: 0;
  line-height: 2rem;
`;
