import { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { isMeetingPath, LOGOUT_PATH } from './paths';
import { logger } from './logger';
import { UserType, SessionCookie, LoginJwt } from '../types';

function getUserFromSessionCookie() {
  const sessionEncoded = Cookies.get('session');
  if (!sessionEncoded) return null;
  try {
    const sessionStringified = window.atob(sessionEncoded);
    const sessionJson = JSON.parse(sessionStringified);
    const sessionCookie = sessionJson.passport.user as SessionCookie;
    logger.setJWT(sessionCookie.loginJwt);
    const decodedLoginJwt = jwt_decode(sessionCookie.loginJwt) as LoginJwt;

    return {
      firmId: String(decodedLoginJwt.firmId),
      loginJwt: sessionCookie.loginJwt,
      uuid: String(decodedLoginJwt.uuid),
      username: decodedLoginJwt.username,
      email: decodedLoginJwt.emailAddress,
      fullName: decodedLoginJwt.fullName,
      userType: decodedLoginJwt.userType,
      sessionId: decodedLoginJwt.sessionId,
      wibCisToken: sessionCookie.wibCisToken,
      phoneNumber: decodedLoginJwt.phoneNumber,
      ibEnabled: decodedLoginJwt.isWebIbEnabled,
      dialInEnabled: decodedLoginJwt.isDialInEnabled,
      localRecordingEnabled: decodedLoginJwt.isLocalRecordingEnabled,
      serverSideRecordingEnabled: decodedLoginJwt.isServerSideRecordingEnabled,
      vhubEnabled: decodedLoginJwt.isVHUBEnabled,
      isInternalUser: decodedLoginJwt.userType === 'bloomberg-employee',
      isQaTester: decodedLoginJwt.userType === 'bloomberg-qa-tester',
      isClient: decodedLoginJwt.userType === 'bloomberg-client',
      restricted: decodedLoginJwt.type === 'restricted-user-login',
      userInitials: decodedLoginJwt.userInitials,
    };
  } catch (e) {
    logger.error("Broken 'session' cookie", Cookies.get('session'));
    return null;
  }
}

const UserContext = createContext<UserType | null>(null);

function useSyncTabsOnUserAuthChange() {
  const [isAuthedInitially] = useState(!!getUserFromSessionCookie());
  useEffect(() => {
    const checkAuthUpdateInterval = setInterval(() => {
      if (isMeetingPath()) {
        // Skip checks when the user is in the meeting
        return;
      }

      const isAuthedNow = !!getUserFromSessionCookie();

      if (isAuthedInitially === isAuthedNow) {
        // User authentication state is unchanged
        return;
      }

      // User authentication state has changed (potentially from another tab)
      isAuthedNow
        ? window.location.reload()
        : (window.location.href = LOGOUT_PATH);
    }, 1000);
    return () => clearInterval(checkAuthUpdateInterval);
  }, [isAuthedInitially]);
}

export { getUserFromSessionCookie, UserContext, useSyncTabsOnUserAuthChange };
