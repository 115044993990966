import styled from 'styled-components';
import RoomNameAutoComplete from './RoomNameAutoComplete';
import { JoinRoomButton } from './JoinRoomButton';
import { RoomInfo } from '../types';
import { device } from '../common/mediaQuery';

const InternalTopSection = ({
  isNarrowScreen,
  selectedRoom,
  setSelectedRoom,
  openDialInModal,
}: {
  isNarrowScreen: boolean;
  selectedRoom: RoomInfo;
  setSelectedRoom: (val: RoomInfo) => void;
  openDialInModal: (val: boolean) => void;
}) => {
  return (
    <InternalTopSectionContainer>
      <RoomNameAutoComplete
        selectedRoom={selectedRoom}
        setSelectedRoom={setSelectedRoom}
      />
      <JoinRoomButton
        selectedRoom={selectedRoom}
        openDialInModal={openDialInModal}
      />
    </InternalTopSectionContainer>
  );
};

const InternalTopSectionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0 calc(6vw - 12px) 0 6vw; /* must match padding in StyledGrid */
  > div:first-child {
    align-items: flex-start;
    margin: 12px 0;
    > div:first-child {
      line-height: 40px;
    }
  }
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    > div:first-child {
      display: block;
      width: 100%;
      > div:first-child {
        line-height: 1;
      }
    }
    .bbui-formfield {
      margin: 8px 0 0 0;
      width: 100%;
    }
    .bbui-autocomplete,
    .bbui-input-group {
      width: 100% !important;
    }
  }
`;

export default InternalTopSection;
