import React, { useContext, useRef, useState } from 'react';
import { Button } from '@bbnpm/bb-ui-framework';
import {
  useVideoStream,
  useSpeaker,
  TestCase,
  TestCaseMessage,
  VideoWithButton,
  useRegisterToggleOfTestUsingSpeaker,
  useStopOtherTestsUsingSpeaker,
} from './common';
import { TestWebRTCContext } from './TestWebRTCContext';

export default function MicSpeakerCam() {
  const { stream, speaker, togglesOfTestsUsingSpeaker } =
    useContext(TestWebRTCContext);

  const [error, setError] = useState('');
  const videoRef = useRef(null);
  const [testStarted, setTestStarted] = useState(true);

  useSpeaker(speaker, videoRef, setError);
  useRegisterToggleOfTestUsingSpeaker(
    togglesOfTestsUsingSpeaker,
    setTestStarted
  );

  useStopOtherTestsUsingSpeaker(
    togglesOfTestsUsingSpeaker,
    setTestStarted,
    testStarted
  );
  useVideoStream(stream, videoRef, testStarted);

  return (
    <TestCase>
      <VideoWithButton>
        <Button onClick={() => setTestStarted(!testStarted)}>
          {testStarted ? 'Stop' : 'Start'}
        </Button>
        <video ref={videoRef} autoPlay />
      </VideoWithButton>
      <TestCaseMessage
        instructionParagraphs={[
          'This test the integration of your microphone, speaker and camera.',
          'Click Start button and you should see your camera view and hear yourself speaking.',
          'Please use your headphone to prevent echos.',
          'Note: starting this test will stop other tests using your speaker.',
        ]}
        error={error}
      />
    </TestCase>
  );
}
