let srcMap = {};
export async function loadScript(src) {
  if (!srcMap[src]) {
    srcMap[src] = new Promise(function (resolve, reject) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
      document.head.appendChild(script);
      srcMap[src] = true;

      script.onerror = () => reject(`Failed to load script ${src}.`);
      script.onload = () => resolve();
    });
  }

  return srcMap[src];
}
