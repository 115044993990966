import { useEffect, useCallback } from 'react';
import { DocumentElement, userDocument } from '../types';

// TODO: figure out jitsiApi type

export function useUpdateFullScreenStatusToJitsi(jitsiApi: any) {
  const onFullScreenChange = useCallback(
    (e: Event) => {
      if (
        e.type === 'fullscreenchange' ||
        e.type === 'mozfullscreenchange' ||
        e.type === 'webkitfullscreenchange'
      ) {
        jitsiApi.executeCommand('setFullScreen', !!document.fullscreenElement);
      }
    },
    [jitsiApi]
  );

  useEffect(() => {
    if (jitsiApi) {
      document.addEventListener('fullscreenchange', onFullScreenChange);
      document.addEventListener('mozfullscreenchange', onFullScreenChange);
      document.addEventListener('webkitfullscreenchange', onFullScreenChange);
      return () => {
        document.removeEventListener('fullscreenchange', onFullScreenChange);
        document.removeEventListener('mozfullscreenchange', onFullScreenChange);
        document.removeEventListener(
          'webkitfullscreenchange',
          onFullScreenChange
        );
      };
    }
  }, [jitsiApi, onFullScreenChange]);
}

export function useCatchFullScreenSignalFromJitsi(jitsiApi: any) {
  useEffect(() => {
    if (jitsiApi) {
      const event = 'fullScreenChanged';
      jitsiApi.addEventListener(event, handleFullscreenChange);
      return () => jitsiApi.removeEventListener(event, handleFullscreenChange);
    }
    function handleFullscreenChange({
      shouldEnterFullscreen,
    }: {
      shouldEnterFullscreen: Boolean;
    }) {
      shouldEnterFullscreen ? enterFullscreen() : exitFullscreen();
    }
    function enterFullscreen() {
      const documentElement: DocumentElement = document.documentElement || {};

      if (typeof documentElement.requestFullscreen === 'function') {
        documentElement.requestFullscreen();
      } else if (typeof documentElement.mozRequestFullScreen === 'function') {
        documentElement.mozRequestFullScreen();
      } else if (
        typeof documentElement.webkitRequestFullscreen === 'function'
      ) {
        documentElement.webkitRequestFullscreen();
      }
    }
    function exitFullscreen() {
      const userDocument: userDocument = document;
      if (userDocument.fullscreenElement) {
        if (typeof userDocument.exitFullscreen === 'function') {
          userDocument.exitFullscreen();
        } else if (typeof userDocument.mozCancelFullScreen === 'function') {
          userDocument.mozCancelFullScreen();
        } else if (typeof userDocument.webkitExitFullscreen === 'function') {
          userDocument.webkitExitFullscreen();
        }
      }
    }
  }, [jitsiApi]);
}
