import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { GlobalLaunchState } from '../common/launchState';

const messageTypes = {
  roomJoined: 'roomJoined',
  roomMuted: 'roomMuted',
  leaveOtherRooms: 'leaveOtherRooms',
};

const broadcastChannel = new BroadcastChannel('roomBroadcastChannel');

export function broadcastRoomJoinedMessage() {
  broadcastChannel.postMessage({
    type: messageTypes.roomJoined,
    appInstanceId: GlobalLaunchState.appInstanceId,
  });
}

export function broadcastLeaveOtherRoomsMessage() {
  broadcastChannel.postMessage({
    type: messageTypes.leaveOtherRooms,
    appInstanceId: GlobalLaunchState.appInstanceId,
  });
}

export function useMuteOtherRooms({
  roomJoined,
  jitsiApi,
  setBannerOtherRoomsMuted,
}) {
  useEffect(() => {
    broadcastChannel.addEventListener('message', handle);
    return () => {
      broadcastChannel.removeEventListener('message', handle);
    };

    function handle(message) {
      switch (message.type) {
        // Mute this room as asked by another room.
        case messageTypes.roomJoined: {
          if (
            roomJoined &&
            message.appInstanceId !== GlobalLaunchState.appInstanceId
          ) {
            muteAudio(jitsiApi);
            muteVideo(jitsiApi);
            // HACK: postMessage doesn't work consistantly without setTimeout.
            setTimeout(
              () =>
                broadcastChannel.postMessage({
                  type: messageTypes.roomMuted,
                  mutedRoomAppInstanceId: GlobalLaunchState.appInstanceId,
                  muterRoomAppInstanceId: message.appInstanceId,
                }),
              100
            );
          }
          break;
        }
        // Show banner that other rooms are muted.
        case messageTypes.roomMuted: {
          if (
            roomJoined &&
            message.mutedRoomAppInstanceId !==
              GlobalLaunchState.appInstanceId &&
            message.muterRoomAppInstanceId === GlobalLaunchState.appInstanceId
          ) {
            setBannerOtherRoomsMuted(true);
          }
          break;
        }
        // Leave this room as asked by another room.
        case messageTypes.leaveOtherRooms: {
          if (
            roomJoined &&
            jitsiApi &&
            message.appInstanceId !== GlobalLaunchState.appInstanceId
          ) {
            jitsiApi.executeCommand('hangup');
          }
          break;
        }
        default:
          break;
      }
    }

    function muteAudio(jitsiApi) {
      jitsiApi
        .isAudioMuted()
        .then((muted) => !muted && jitsiApi.executeCommand('toggleAudio'));
    }

    function muteVideo(jitsiApi) {
      jitsiApi
        .isVideoMuted()
        .then((muted) => !muted && jitsiApi.executeCommand('toggleVideo'));
    }
  }, [jitsiApi, roomJoined, setBannerOtherRoomsMuted]);
}
