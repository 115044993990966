// Paths supported in the app
export const ERROR_PATH = '/error';
export const HELP_PATH = '/help';
export const HOME_PATH = '/home';
export const LANDING_PATH = '/landing';
export const LOGOUT_PATH = '/logout';
export const MEETING_PATH = '/rooms/:roomId';
export const ROOT_PATH = '/';
export const TEST_WEBRTC_PATH = '/test';

export function isMeetingPath() {
  // Paths with '/rooms' are meeting URLs unless there is a '#preview' which indicates preview screen
  return (
    window.location.pathname.indexOf('/rooms') !== -1 &&
    window.location.hash !== '#preview'
  );
}
