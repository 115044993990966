import styled from 'styled-components';
import { Tooltip } from '@bbnpm/bb-ui-framework';
import RadioOnIcon from '@bloomberg/web-icons-react/RadioOn';

export default function RecordingPill({ isNarrow, recorder, recordingStatus }) {
  if (!recorder && !recordingStatus) {
    return null;
  }

  return (
    <>
      {isNarrow ? (
        <Recording>
          <RadioOnIcon />
        </Recording>
      ) : (
        <Tooltip
          content={
            <p>
              Recording in progress.
              <br />
              {makeToolTip(recorder)}
            </p>
          }
        >
          <Recording>
            <RecordingIcon name='radio-on' /> REC
          </Recording>
        </Tooltip>
      )}
    </>
  );
}

function makeToolTip(recorder) {
  if (recorder) {
    return `Initiated by ${recorder.name}`;
  } else {
    return `Initiated by a participant`;
  }
}

const Recording = styled.div`
  background-color: #e51e3c;
  border-radius: 1rem;
  display: flex;
  font-size: 0.875rem;
  color: #fff;
  height: 2rem;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.25rem 0.75rem;
  width: max-content;
  align-items: center;
`;

const RecordingIcon = styled(RadioOnIcon)`
  display: inline-block;
  position: relative;
  vertical-align: -0.125em;
  margin-right: inherit;
`;
