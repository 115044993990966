import { GlobalLaunchState } from './launchState';
import { logger } from './logger';

const BSSO_PROD_BASE_URL = 'https://bsso.blpprofessional.com';
const BSSO_PROD_OAUTH2_CLIENT_ID = 'https://live.blproom.com'; // {PWHO //ID 3169093 <GO>}
const BSSO_OAUTH2_AUTHZ_ENDPOINT = '/as/authorization.oauth2';
const ROOM_OAUTH2_REDIRECT_ENDPOINT = '/api/login/oauth2/redirect'; // Matching handler is in bbroom-web-backend

function appendLaunchStateForPostLogin(url: string): string {
  try {
    const urlObject = new URL(url);
    // Retain the App mode that was used to launch - embedded in Terminal or using browser
    if (!GlobalLaunchState.isWeb) {
      urlObject.searchParams.set('terminal', '');
    }
    // Retain App Instance ID for maintaining continuity of logs for this particular app launch
    urlObject.searchParams.set(
      'appInstanceId',
      GlobalLaunchState.appInstanceId
    );
    // Retain BEVO Session ID for maintaining continuity of user interaction data after login
    if (GlobalLaunchState.bevoSessionId) {
      urlObject.searchParams.set(
        'bevoSessionId',
        GlobalLaunchState.bevoSessionId
      );
    }
    // Make sure sensitive information like the OpenToken is removed after use
    urlObject.searchParams.delete('bssoOpenToken');
    return urlObject.toString();
  } catch (e) {
    // Invalid string can't be converted to URL object
    logger.error(
      `Invalid string given as redirect URL after login with OpenToken - ${url}`
    );
  }
  return url;
}

function createRoomOAuth2RedirectUri(): string {
  // Add yourself to {PWHO //ID 3169093 <GO>} and check the currently configured redirect URIs in {SSAM <GO>}.
  // Submit a ticket to DRQS Group 363 to modify the BSSO Connection.
  return (
    (GlobalLaunchState.isLocal
      ? 'http://localhost:3001'
      : window.location.origin) + ROOM_OAUTH2_REDIRECT_ENDPOINT
  );
}

export function createBssoOpenTokenUrl(bssoOpenToken: string): string {
  return (
    BSSO_PROD_BASE_URL +
    BSSO_OAUTH2_AUTHZ_ENDPOINT +
    '?scope=openid%20profile&response_type=code&client_id=' +
    BSSO_PROD_OAUTH2_CLIENT_ID +
    '&redirect_uri=' +
    encodeURIComponent(createRoomOAuth2RedirectUri()) +
    '&ssotoken=' +
    bssoOpenToken +
    '&adapter=token&state=' +
    encodeURIComponent(appendLaunchStateForPostLogin(window.location.href))
  );
}
