import { Button } from '@bbnpm/bb-ui-framework';
import React, { useEffect, useState, useRef } from 'react';
import {
  TestCaseMessage,
  TestCase,
  useVideoStream,
  VideoWithButton,
} from './common';

export default function ScreenShare() {
  const [error, setError] = useState('');
  const videoRef = useRef(null);
  const [screenShareStarted, setScreenShareStarted] = useState(false);

  const stream = useGetDisplayStream(
    screenShareStarted,
    setError,
    setScreenShareStarted
  );
  useHandleDisplayStreamEnd(stream, setScreenShareStarted);
  useHandleStopScreenShare(screenShareStarted, stream);
  useVideoStream(stream, videoRef, screenShareStarted);

  return (
    <TestCase>
      <VideoWithButton>
        <Button onClick={() => setScreenShareStarted(!screenShareStarted)}>
          {screenShareStarted ? 'Stop' : 'Start'}
        </Button>
        <video ref={videoRef} autoPlay />
      </VideoWithButton>
      <TestCaseMessage
        instructionParagraphs={[
          'This tests your ability to share screen.',
          'Click the "Start" button and follow the prompt. You should see your shared screen.',
        ]}
        error={error}
      />
    </TestCase>
  );
}

function useHandleStopScreenShare(screenShareStarted, stream) {
  useEffect(() => {
    if (!screenShareStarted && stream) {
      stream.getTracks().forEach((t) => t.stop());
    }
  }, [screenShareStarted, stream]);
}

function useHandleDisplayStreamEnd(stream, setScreenShareStarted) {
  useEffect(() => {
    if (stream) {
      stream
        .getVideoTracks()[0]
        .addEventListener('ended', handleDisplayStreamEnd);
      return () =>
        stream
          .getVideoTracks()[0]
          .removeEventListener('ended', handleDisplayStreamEnd);
    }

    function handleDisplayStreamEnd() {
      setScreenShareStarted(false);
    }
  }, [stream, setScreenShareStarted]);
}

function useGetDisplayStream(
  screenShareStarted,
  setError,
  setScreenShareStarted
) {
  const [stream, setStream] = useState(undefined);

  useEffect(() => {
    if (screenShareStarted) {
      getDisplayStream();
    }

    async function getDisplayStream() {
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        setStream(stream);
        setError('');
      } catch (e) {
        setScreenShareStarted(false);
        setError(e);
        console.error(e);
      }
    }
  }, [screenShareStarted, setScreenShareStarted, setError]);

  return stream;
}
